//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CheckCircleFilled, LogoutOutlined } from '@ant-design/icons';
import { ChartsPieWidget9 } from '@metronic/partials/widgets/charts/ChartsPieWidget9';
import { Button, Card, Col, Drawer, QRCode, Row, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import { find, findIndex, forEach, get, map, random } from 'lodash';
import Lottie from 'lottie-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { animated, config, useSpring, useTransition } from 'react-spring';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactWordcloud from 'react-wordcloud';
import WantingScreentLoader from '../../StudentEnd/PlayingQuizEnd/WantingScreentLoader';
import { getGamePreviewWithoutAuth, getGameQuestions, getGameQuestionsWithoutAuth } from '../../store/GameQuestions/thunks';
import BlueMonsterdata2 from '../../utils/assets/lottie animations/BlueMonsterdata.json';
import { getModernImage, isImageUrl } from '../../utils/methods/QuizeMethodData';
import CountdownStopwatch from '../CountDownFlip';
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import styled from 'styled-components';
import '../../ModernResultScreen/modern-style.css';
import 'animate.css'
import {
    SoccerFootball
} from '../../utils/assets/images/index';
import { useCurrentGameSession } from '../../store/game_session/selectors';
import { IGameLogos } from '../../utils/assets/images';
import { avtarData } from '../AvatarModal/avtarData';
import ModernScoreAsked from '../../ModernResultScreen/modernScoreAsked';

const ModernSection = styled.div`
  left: ${({ player, rank }) => (player?.modernFinalScore > 0 ? `calc(15vw - ${rank * 50}px)` : '0vw')};
  width: ${({ size }) => `${size}px`};
  height:90px;

  @media (max-width: 992px) {
    left: ${({ player, rank }) => (player?.modernFinalScore > 0 ? `calc(25.5vw - ${rank * 50}px)` : '0vw')};
    width: ${({ size }) => `${size * 1.5}px`};
  }
`


export const COTATION_CORRECT = [
    {
        message: "iMira nada mas quien te viera!",
        imageUrl: require('../../utils/assets/images/img21.gif'),
        correctType: "heart"
    },
    {
        message: "¡Buen trabajo!",
        imageUrl: require('../../utils/assets/images/img22.gif'),
        correctType: "clap"
    },
    {
        message: "iQue chevere",
        imageUrl: require('../../utils/assets/images/img23.gif'),
        correctType: "heart-smile"
    },
    {
        message: "iEso, parce!",
        imageUrl: require('../../utils/assets/images/img25.gif'),
        correctType: "thumps-up"
    },
    {
        message: "¡Mira nada más quién te viera!",
        imageUrl: require('../../utils/assets/images/img24.gif'),
        correctType: "heart-happy"
    },
    {
        message: "iPura vida!",
        imageUrl: require('../../utils/assets/images/img42.gif'),
        correctType: "heart-dance"
    },
    {
        message: "¡SOS, un crack!",
        imageUrl: require('../../utils/assets/images/img26.gif'),
        correctType: "style-emoji"
    },
    // {
    //     message: "iQue chido!",
    //     imageUrl: require('../../utils/assets/images/football.gif'),
    //     correctType: "emoji-football"
    // },
];

export const COTATION_INCORRECT = [
    {
        message: "¡No pasa nada, sigue intentando!",
        imageUrl: require('../../utils/assets/images/incorrect1.gif'),
        correctType: "emoji-incorrect1"
    },
    {
        message: "iSigue intentando, si puedes!",
        imageUrl: require('../../utils/assets/images/incorrect4.gif'),
        correctType: "emoji-incorrect2"
    },
    // {
    //     message: "iSiguele, siguele, vamos!",
    //     imageUrl: require('../../utils/assets/images/incorrect6.gif'),
    //     correctType: "emoji-incorrect3"
    // },
    // {
    //     message: "iSiguele, siguele, vamos!",
    //     imageUrl: require('../../utils/assets/images/incorrect5.gif'),
    //     correctType: "emoji-incorrect4"
    // },
];


export const getRandomCotation = (isCorrect) => {
    if (isCorrect) {
        const rendNum = random(0, COTATION_CORRECT?.length - 1);
        return COTATION_CORRECT[rendNum];

    } else {
        const rendNum = random(0, COTATION_INCORRECT?.length - 1);
        return COTATION_INCORRECT[rendNum];
    }
}

const UserList = ({ users }) => {
    // Track the previous length of the users array to identify new users
    const [prevLength, setPrevLength] = useState(users.length);
    useEffect(() => {
        if (users.length > prevLength) {
            setPrevLength(users.length);
        }
    }, [users, prevLength]);

    const transitions = useTransition(users, {
        from: { transform: 'translateY(20px)', opacity: 0 },
        enter: { transform: 'translateY(0)', opacity: 1 },
        leave: { transform: 'translateY(-20px)', opacity: 0 },
        keys: users?.map((item, index) => index),
    });

    return (
        <div className='prvwUsrAddon'>
            {transitions((styles, item) => (
                <div>
                    <Card
                        hoverable={false}
                        className='cardBgPlayer'>
                        <div className='PlayerConatinerSection previewAvat'>
                            <Meta
                                avatar={<Lottie animationData={BlueMonsterdata2} />}
                                title={get(item, "user_name", "--")}
                            />
                        </div>
                    </Card>
                </div>

            ))}
        </div>
    );
};


const StudentChart = ({ playerResult, active_question_id, type }) => {
    const submitted_data = {
        no_response: 5,
        correct_response: 2,
        in_correct_response: 5
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: 15 }} className="piechartProj">
                <ul className='chartdescpText'>
                    <li className='chartdescpCrct'><span></span>Correct answers</li>
                    <li className='chartdescpInCrct'><span></span>Incorrect answers</li>
                    <li className='chartdescpNo'><span></span>No response</li>
                </ul>

                <ChartsPieWidget9
                    chartType="PieChart"
                    data={submitted_data}
                    // options={options}
                    width={"100%"}
                    height={"400px"}
                // type={type}
                />
            </div>
        </div>
    );
};


const StudentEndResult = ({ currentQuestion, quotation, nextSection = () => { }, isCorrect, selectedAnswersIndex, selectedAnswers, isLastQuestion, quizAnswers, currentScoreModern, gamePreviewPin }) => {

    // const getRandomValue = () => {
    //     // Generate random values for animation parameters
    //     const opacity = Math.random();
    //     const translateX = Math.random() * 1000 - 500; // Random value between -500 and 500
    //     const translateY = Math.random() * 1000 - 500; // Random value between -500 and 500
    //     const duration = Math.random() * 2000; // Random duration between 1000ms and 3000ms

    //     return { opacity, translateX, translateY, duration };
    // };
    const ZoomBounceRsultIcon = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 1300,
    });
    const ZoomBounceRsult = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 800,
    });
    const TopBounceBG = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 500,
    });

    const CloseImgProps = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 600,
    });
    const ZoomBounceRsultTxt = useSpring({
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 1800,
    });

    const { currentGameSession } = useCurrentGameSession();


    useEffect(() => {
        setTimeout(() => {
            nextSection()
        }, 8000);
    }, [])
    return (
        <div className='row g-0'>
            <div className='col-11 col-sm-11 col-md-10 col-lg-5 stdntCardUpr stdntCardUprss no-select' onContextMenu={(e) => e.preventDefault()}>
                {gamePreviewPin.game_mode === "modern" && (
                    <div style={{ textAlign: "center" }}>
                        <span className='studentModernPlace'>{`Place: 1`}</span>
                        <span className='studentModernPlaceScore'>{currentScoreModern[0]?.modernFinalScore}</span>
                    </div>
                )}
                <animated.div style={TopBounceBG} className='stdntCard'>
                    {(currentQuestion?.type === 'brainstorm' ||
                        currentQuestion?.type === 'open_ended' ||
                        currentQuestion?.type === 'word_cloud'
                        // currentQuestion?.type === 'poll' ||
                        // currentQuestion?.type === 'drop_pin'
                    ) ? <p>Your response is successfully submitted !</p>
                        : (
                            <>
                                <div className='stdntCardImg'>
                                    <animated.img style={CloseImgProps} src={isCorrect ? require('../../utils/assets/images/greenRight.png') : require('../../utils/assets/images/close.png')} alt="" />
                                </div>
                                {currentQuestion?.type === 'quiz' || currentQuestion?.type === 'true_or_false' ? (
                                    <p>
                                        {selectedAnswers ? (
                                            // `(${String.fromCharCode(65 + selectedAnswersIndex)}) ${selectedAnswers}`
                                            <>
                                                {isImageUrl(selectedAnswers) ? (
                                                    <>
                                                        <img src={selectedAnswers} className='imgContainerAQs' alt="" />
                                                    </>
                                                ) : (
                                                    `(${String.fromCharCode(65 + selectedAnswersIndex)}) ${selectedAnswers}`

                                                )}
                                            </>
                                        ) : (
                                            'Oops! No answer was submitted for this question.'
                                        )}
                                    </p>
                                ) : (
                                    isImageUrl(selectedAnswers) ? (
                                        <>
                                            <img src={selectedAnswers} alt={`${selectedAnswers}`} className='imgContainerAQ answerImgResult' />
                                        </>) : (
                                        currentQuestion?.type === 'puzzle' ? (
                                            <div className='numParaLmt'>
                                                <ul>
                                                    {Array.isArray(selectedAnswers) ? selectedAnswers.map((item, index) => {
                                                        const isCorrect = item === quizAnswers.answer[index];
                                                        return (
                                                            <div key={index} className='puzzleItem'>
                                                                <div className='textareadragPart'>
                                                                    <div className='responseDragDrop'>
                                                                        <>
                                                                            {isCorrect ? (
                                                                                <CheckCircleIcon className='trueSuffleIcon studyResultTrueIcon studyResultTrueIconGame' />
                                                                            ) : (
                                                                                <CancelIcon className='falseSuffleIcon studyResultTrueIcon studyResultTrueIconGame' />
                                                                            )}
                                                                            <li key={index}>
                                                                                {item}
                                                                            </li>
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }) : <p>{'Oops! No answer was submitted for this question.'}</p>}
                                                    {/* {Array.isArray(selectedAnswers) ? selectedAnswers.map((item) => (
                                                        <li>{item}</li>
                                                    ))
                                                    : <p>{'Oops! No answer was submitted for this question.'}</p>} */}
                                                </ul>
                                            </div>
                                        ) : (
                                            <>
                                                <p>{selectedAnswers || 'Oops! No answer was submitted for this question.'}</p>
                                            </>
                                        )

                                    )
                                )}
                            </>
                        )}
                </animated.div>
                {quotation?.correctType === 'heart' && (
                    <div className={quotation?.correctType === 'heart' ? 'cardImprsnUpr' : quotation?.correctType === 'clap' ? 'cardEmojy cardEmojy6 cardEmojyClap' : 'cardEmojy cardEmojy6 cardEmojyClap'}>
                        <animated.img className={quotation?.correctType === 'heart' ? '' : quotation?.correctType === 'clap' ? 'clapimgposition' : 'clapimgposition'} style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.div style={ZoomBounceRsult} className='cardImprsn'>
                            <animated.p style={ZoomBounceRsultTxt}>{quotation?.message}</animated.p>
                        </animated.div>
                    </div>
                )}
                {quotation?.correctType === 'clap' && (
                    <animated.div style={ZoomBounceRsult} className='cardEmojy cardEmojy6 cardEmojyClap'>
                        <animated.img className='clapimgposition' src={quotation?.imageUrl} alt="" style={ZoomBounceRsultIcon} />
                        <animated.p style={ZoomBounceRsultTxt}>{quotation?.message}</animated.p>
                    </animated.div>
                )}
                {quotation?.correctType === 'heart-smile' || quotation?.correctType === 'emoji-incorrect4' && (
                    <animated.div style={TopBounceBG} className='cardEmojy2'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                    </animated.div>
                )}
                {quotation?.correctType === 'thumps-up' && (
                    <animated.div style={ZoomBounceRsult} className='cardFlagUpr'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.div className='cardFlag' style={ZoomBounceRsult}>
                            <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                        </animated.div>
                    </animated.div>
                )}
                {quotation?.correctType === 'heart-happy' && (
                    <animated.div style={ZoomBounceRsult} className='cardImprsnUpr'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.div className='cardImprsn' style={ZoomBounceRsult}>
                            <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                        </animated.div>
                    </animated.div>
                )}
                {quotation?.correctType === 'heart-dance' && (
                    <animated.div style={ZoomBounceRsult} className='cardhrtUpr'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.div className='cardhrt' style={ZoomBounceRsult}>
                            <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                        </animated.div>
                    </animated.div>
                )}
                {quotation?.correctType === 'style-emoji' && (
                    <animated.div style={ZoomBounceRsult} className='cardhrt2Upr'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.div className='cardhrt2' style={ZoomBounceRsult}>
                            <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                        </animated.div>
                    </animated.div>
                )}
                {quotation?.correctType === 'emoji-football' && (
                    <animated.div style={ZoomBounceRsult} className='cardhrt2Upr cardhrt3Upr'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <div className='cardhrt2 cardhrt3' style={ZoomBounceRsult}>
                            <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                        </div>
                    </animated.div>
                )}
                {quotation?.correctType === 'emoji-incorrect1' && (
                    <animated.div style={ZoomBounceRsult} className='cardEmojy cardEmojyUp greenIconPara'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                    </animated.div>
                )}
                {quotation?.correctType === 'emoji-incorrect2' && (
                    <animated.div style={ZoomBounceRsult} className='cardEmojy cardEmojyThink greenIconPara2'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                    </animated.div>
                )}
                {quotation?.correctType === 'emoji-incorrect3' && (
                    <animated.div style={ZoomBounceRsult} className='cardEmojy2 cardEmojyFace'>
                        <animated.img style={ZoomBounceRsultIcon} src={quotation?.imageUrl} alt="" />
                        <animated.p style={ZoomBounceRsultTxt} >{quotation?.message}</animated.p>
                    </animated.div>
                )}
            </div>
        </div>
    )
}

const ScoreDesign = ({ onNext = () => { }, exitButton, currentQuestion, active_question_id }) => {
    const [isTypingOut, setIsTypingOut] = useState(false);
    const [randomizeResult, setRandomizeResult] = useState();
    const playerList3 =
        [{
            "user_name": "Iris student",
            "score": 1
        },
        {
            "user_name": "Carlos",
            "score": 0
        },
        {
            "user_name": "Iris Student",
            "score": 2
        }];

    const playerList5 = [{
        "user_name": "Iris1 student",
        "score": 1
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    }
    ];

    const playerList10 = [{
        "user_name": "Iris1 student",
        "score": 1
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    },
    {
        "user_name": "Iris1 student",
        "score": 1
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    },
    {
        "user_name": "Carlos",
        "score": 0
    },
    {
        "user_name": "Iris Student",
        "score": 2
    }
    ];

    useEffect(() => {
        renderResultTable();
    }, []);
    const renderResultTable = () => {
        const counts = [3, 5, 10, 1];
        const randomCount = counts[Math.floor(Math.random() * counts.length)]
        setRandomizeResult(randomCount);
    }
    useEffect(() => {
        const typingInterval = setInterval(() => {
            setIsTypingOut(true);
        }, 6000);

        return () => clearInterval(typingInterval);
    }, []);
    const { textWidth } = useSpring({
        from: { textWidth: isTypingOut ? '100%' : '0%' },
        to: { textWidth: isTypingOut ? '0%' : '100%' },
        config: { duration: 300 },
    });

    const textStyles = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        animation: 'typingAnim 3s steps(50)',
    };

    const animatedTextStyles = {
        ...textStyles,
        width: textWidth,
    };
    const nextSection = () => {
        onNext();
    };
    return (
        <div className="teacherQuesView scoreAnsView">
            {currentQuestion?.type === 'poll' ?
                <StudentChart playerResult={[1, 2]} active_question_id={active_question_id} />
                :
                <div className="no-select" onContextMenu={(e) => e.preventDefault()}>

                    {randomizeResult === 3 && (
                        <div className="allQuizScoreSection">
                            <div className="quizScoreAwards">
                                <div className="topScoreSection topScoreSecondSec">
                                    <div className="typing">
                                        <animated.p style={animatedTextStyles}>
                                            La tabla del saber
                                        </animated.p>
                                    </div>
                                    <img src={require('../../utils/assets/images/globe.gif')} alt="" />
                                </div>
                                {map(playerList3.slice(0, 3), (player, index) => (
                                    <div key={index} className="scoreSectionTxt allScoreTxt addScoreUl">
                                        <p>{index + 1}</p>
                                        <ul className={`guiz-awards-row quizAwardTxt quizBestPlayLst`}>
                                            <li className="quizAwardstTitle">{player?.user_name || ""}</li>
                                            <li className="guiz-awards-track">{`${player.score} correct answers`}</li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {randomizeResult === 5 && (
                        <div className="allQuizScoreSection">
                            <div  >
                                <div className="topScoreSection">
                                    <div className="typing">
                                        <animated.p style={animatedTextStyles}>
                                            Best players!
                                        </animated.p>
                                    </div>
                                    <img src={require('../../utils/assets/images/incorrect4.gif')} alt="" />
                                </div>
                                {map(playerList5.slice(0, 5), (player, index) => (
                                    <div className="scoreSectionTxt" key={index}>
                                        <p>{index + 1}</p>
                                        <ul className={`guiz-awards-row ${index % 2 === 0 ? 'guiz-awards-row-even' : ''}`}>
                                            <li className="quizAwardstTitle">{player?.user_name || ""}</li>
                                            <li className="guiz-awards-track">{`${player.score} correct answers`}</li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {randomizeResult === 10 && (
                        <div className="allQuizScoreSection longTableData">
                            <div  >
                                <div className="topScoreSection">
                                    <div className="typing">
                                        <animated.p style={animatedTextStyles}>
                                            Welcome to the future
                                        </animated.p>
                                    </div>
                                    <img src={require('../../utils/assets/images/incorrect1.gif')} alt="" />
                                </div>
                                {map(playerList10.slice(0, 8), (player, index) => (
                                    <div className="scoreSectionTxt" key={index}>
                                        <p>{index + 1}</p>
                                        <ul className={`guiz-awards-row ${index % 2 === 0 ? 'guiz-awards-row-even' : ''}`}>
                                            <li className="quizAwardstTitle">{player?.user_name || ""}</li>
                                            <li className="guiz-awards-track">{`${player.score} correct answers`}</li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {randomizeResult === 1 && (
                        <div className="allQuizScoreSection">
                            <div  >
                                <div className="topScoreSection topBeatingSection">
                                    <div className="">
                                        <animated.p style={animatedTextStyles}>
                                            Beating everyone!
                                        </animated.p>
                                    </div>
                                </div>
                                <div className="allBeatingTxt no-select" onContextMenu={(e) => e.preventDefault()}>
                                    {map(playerList3.slice(0, 1), (player, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col xs={{ span: 11 }} sm={{ span: 7 }} lg={{ span: 7 }} className="resultFstWiner centertext">
                                                    <div className="resultLstImgPart" style={{ marginTop: 10 }}>

                                                        <img src={require('../../utils/assets/images/Beating.png')} alt="" />

                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 13 }} sm={{ span: 8 }} lg={{ span: 8 }} className="resultFstWiner centertext">
                                                    <animated.div
                                                        className="resultLstTxtPart"
                                                    // style={thirdResultAnimation}
                                                    >
                                                        <span>{player?.user_name || ""}</span>
                                                    </animated.div>
                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 9 }} lg={{ span: 9 }} className="resultFstWiner ">
                                                    <div className="allBeatingImg">
                                                        <img src={require('../../utils/assets/images/dance_human.gif')} alt="" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            <div className='row g-0 correctNxtBtnUpr'>
                <div className='col-12 nextBkBtnUpr'>
                    {exitButton()}
                    <div className='teacherVwNextBtn previewTeacherNextBtn'><Button onClick={nextSection}>Next!</Button></div>
                </div>
            </div>
        </div>
    )
}

// const initialUpdateAnsData = {
//     session_id: '',
//     answers: [],
//     score: '',
//     game_id: ''
// };

const PreviewGame = ({ isOpen, isClose, GameId, nosotros }) => {
    const [users, setUsers] = useState([])
    const maxItems = 6;
    const [questionPage, setQuestionPage] = useState('waiting')
    const [showResult, setShowResult] = useState(false);
    const [GameQuestions, setGameQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const currentQuestion = GameQuestions[currentQuestionIndex];
    const [time, setTime] = useState(0);
    const [gamePreviewPin, setGamePreviewPin] = useState(2);
    const [timeModern, setTimeModern] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState('');
    const [pin, setPin] = useState('');
    const [items, setItems] = useState([]);
    const [itemsPuzzleAnswers, setItemsPuzzleAnswers] = useState([])
    const [image, setImage] = useState(null);
    // const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
    const [showStudentWait, setShowStudentWait] = useState(false)
    // const [updateAnsData, setUpdateAnsData] = useState(initialUpdateAnsData);
    // const [location1, setLocation] = useState({ lat: 0, lng: 0 });
    // const [dragging, setDragging] = useState(false);
    const [selectedAnswersIndex, setSelectedAnswersIndex] = useState();
    const [isCorrect, setIsCorrect] = useState(false);
    const [quotation, setQuotation] = useState(getRandomCotation());
    // const { firestore } = useFirebase()
    // const [selectedData, setSelectedData] = useState('');
    const [gameEnd, setGameEnd] = useState(false);
    const [showMessage, setShowMessage] = useState(true);
    const [showQuestion, setShowQuestion] = useState(true);
    const getQuestionUrl = get(currentQuestion, 'question.imageUrl', '') ?? {};
    // const [wordCloudSubmission, setWordCloudSubmission] = useState([])
    const optionsCloud = {
        colors: ["#510706", "#a6280d", "#ba6c2d", "#d08c37", "#7c6434", "#8c564b"],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [20, 60],
        fontStyle: "normal",
        fontWeight: "600",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, -90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };
    const [showScore, setShowScore] = useState(false);
    const [showChart, setShowChart] = useState(false);
    // const [showPlayerResult, setShowPlayerResult] = useState([]);
    const [active_question_id, setActiveQuestion_id] = useState(null);
    // const [nextPage, setNextPage] = useState(1);
    const [topLogoBack, setTopLogoBack] = useState(true);
    const [topLogoText, setTopLogoText] = useState(true);
    const [topLogoTextSec, setTopLogoTextSec] = useState(true);
    const [gmeGifImage, setGmeGifImage] = useState(true);
    const [showTextTwo, setShowTextTwo] = useState(true);
    const [sideImgSec, setSideImgSec] = useState(true);
    const [showText, setShowText] = useState(false);
    const [showTextsResult, setShowTextsResult] = useState(true);
    const [finalResult, setFinalResult] = useState(false);
    const [start_loader, set_start_loader] = useState(false);
    const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "¡"];
    const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "¡"];
    const timess = new Date();
    const [avatarChoosed, setAvatarChoosed] = useState(false);
    const [randomPageIndex, setRandomPageIndex] = useState(0);
    const [loadingTeacher, setLoadingTeacher] = useState(false);
    // let monsterList = avtarData;
    // const [selectedAvatar, setSelectedAvatar] = useState(2);
    timess.setSeconds(timess.getSeconds() + 6);
    const [inputWord, setInputWord] = useState('');
    const [bestPlayerScrn, setBestPlayerScrn] = useState(true);
    const [questionstart, setQuestionStart] = useState(false)
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState(['Answer Questions', 'Question BrainStorm']);
    const [size, setSize] = useState('100%')
    const [modernScoreConfirm, setmodernScoreConfirm] = useState(false);
    const [showGameResult, setShowGameResult] = useState(false);
    // const nextTypes = ['true_or_false', 'poll'];
    const nextTypes = gamePreviewPin.game_mode === "modern" ? [] : ['true_or_false', 'poll']
    const [showPlayerResult, setShowPlayerResult] = useState([
        {
            "user_name": "Dummy 1",
            "score": 0,
            "modernFinalScore": 0,
            "avatarImg": 2

        },
    ]);
    const [showPlayerResultStc, setShowPlayerResultStc] = useState([
        {
            "user_name": "Dummy 2",
            "score": 0,
            "modernFinalScore": 0,
            "avatarImg": 3
        },
        {
            "user_name": "Dummy 3",
            "score": 0,
            "modernFinalScore": 0,
            "avatarImg": 2

        },
        {
            "user_name": "Dummy 4",
            "score": 0,
            "modernFinalScore": 0,
            "avatarImg": 5
        },
        {
            "user_name": "Dummy 5",
            "score": 0,
            "modernFinalScore": 0,
            "avatarImg": 6
        },
    ]);
    const [highestScor, setHighestScor] = useState({});
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [fadeIn, setFadeIn] = useState(true)
    const [modernImage, setModernImage] = useState([])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFadeIn(false)
        }, 5000)

        return () => clearTimeout(timeout)
    }, [currentImageIndex, fadeIn])

    useEffect(() => {
        if (modernImage?.length > 0) {
            const timeout = setTimeout(() => {
                const nextIndex = (currentImageIndex + 1) % modernImage?.length
                setCurrentImageIndex(nextIndex)
                playInSound(inSound);
                setFadeIn(true)
            }, 10000)
            return () => clearTimeout(timeout)
        }
    }, [currentImageIndex, modernImage])

    const playInSound = (soundFile) => {
        if (soundFile) {
            const audio = new Audio(soundFile);
            audio.loop = false;
            audio.play();
        }
    };

    const handleButtonClick = (newVal) => {
        // Update modernFinalScore by adding 2 to it for each player
        const updatedPlayers = showPlayerResult.map(player => ({
            ...player,
            modernFinalScore: player.modernFinalScore + newVal
        }));
        setShowPlayerResult(updatedPlayers);
    };

    const handleButtonClickStatic = (value) => {
        const randomNums = [];
        // Generate a random number between 0 and 5 for each player
        for (let i = 0; i < showPlayerResultStc.length; i++) {
            randomNums.push(Math.floor(Math.random() * value));
        }
        const updatedPlayers = showPlayerResultStc.map((player, index) => (
            {
                ...player,
                modernFinalScore: player.modernFinalScore + randomNums[index]
            }));
        setShowPlayerResultStc(updatedPlayers);
        const highestScorer = updatedPlayers.reduce((prev, current) => {
            return (prev.modernFinalScore > current.modernFinalScore) ? prev : current;
        });
    };

    // useEffect(() => {
    //  setInterval(() => {
    //         handleButtonClickStatic();
    //   }, 5000);
    // }, [])
    useEffect(() => {
        if (!showGameResult) { // Check if newState is false
            const intervalId = setInterval(() => {
                handleButtonClickStatic(showPlayerResult[0]?.modernFinalScore);
            }, 5000);
            // Clean up the interval when component unmounts or when newState becomes true
            return () => clearInterval(intervalId);
        }
    }, [showGameResult, showPlayerResult]);

    const [rankposition, setRankposition] = useState([1, 4])


    const [words, setWords] = useState([
        {
            text: 'told',
            value: 1,
        },
        {
            text: 'mistake',
            value: 2,
        },
        {
            text: 'thought',
            value: 3,
        },
        {
            text: 'bad',
            value: 4,
        }])
    const [isTypingOut, setIsTypingOut] = useState(false);
    useEffect(() => {
        const typingInterval = setInterval(() => {
            setIsTypingOut(true);
        }, 6000);

        return () => clearInterval(typingInterval);
    }, []);

    // useEffect(() => {
    //     enjoyingGameScreen();
    // },[])
    const enjoyingGameScreen = () => {
        setTimeout(() => {
            setBestPlayerScrn(false);
        }, 6000);
    }



    // const { textWidth } = useSpring({
    //     from: { textWidth: isTypingOut ? '100%' : '0%' },
    //     to: { textWidth: isTypingOut ? '0%' : '100%' },
    //     config: { duration: 300 },
    // });

    // const textStyles = {
    //     overflow: 'hidden',
    //     whiteSpace: 'nowrap',
    //     animation: 'typingAnim 3s steps(50)',
    // };

    // const animatedTextStyles = {
    //     ...textStyles,
    //     width: textWidth,
    // };

    useEffect(() => {
        const interval = setInterval(() => {
            if (users?.length < maxItems) {
                const newObj = {
                    id: "32b33e7c-9a34-46bb-9680-0acdc401e793048537630-d781-4eb0-8c3e-43dcd1eb756f",
                    session_id: "04853760-d781-4eb0-8c3e-43dcd1eb756f",
                    user_id: "32b33e7c-9a34-46bb-9680-0acdc401e793",
                    user_name: `Dummy ${users?.length + 1}`
                };
                // Replace this with your actual object
                setUsers((prevArray) => [...prevArray, newObj]);
            } else {
                clearInterval(interval); // Terminate the interval after reaching the maximum number of items
            }
        }, 2500); // 5000 milliseconds = 5 seconds

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, [users, maxItems]);

    const getGame = async (getGameData) => {
        set_start_loader(true)
        const data = await getGameQuestions(GameId, getGameData.game_mode);
        setGameQuestions(data);
        set_start_loader(false)
    }
    const getGameWithoutAuth = async (getGameData) => {
        set_start_loader(true)
        const data = await getGameQuestionsWithoutAuth(GameId, getGameData.game_mode);
        setGameQuestions(data);
        set_start_loader(false)
    }
    useEffect(() => {
        getgamePreviewData();
    }, [GameId])

    const getgamePreviewData = async () => {
        const getGameData = await getGamePreviewWithoutAuth(GameId);
        if (nosotros) {
            getGameWithoutAuth(getGameData);
        }
        else {
            getGame(getGameData);
        }
        setGamePreviewPin(getGameData);
    }

    const getType = (type) => {
        if (type === 'quiz') {
            return 'Quiz';
        } else if (type === 'true_or_false') {
            return 'True or False';
        } else if (type === 'type_answer') {
            return 'Type Answer';
        } else if (type === 'puzzle') {
            return 'Puzzle';
        } else if (type === 'poll') {
            return 'Poll';
        } else if (type === 'drop_pin') {
            return 'Drop Pin';
        } else if (type === 'word_cloud') {
            return 'Word Cloud';
        } else if (type === 'open_ended') {
            return 'Open Ended';
        } else if (type === 'brainstorm') {
            return 'Brainstorm';
        } else if (type === 'informational_page') {
            return 'Informational Page';
        } else {
            return '';
        }
    }



    const [showAnimations, setShowAnimations] = useState(false);

    // const fadeInLeftAnimation = useSpring({
    //     from: { opacity: 0, transform: 'translateX(1000px)' },
    //     to: { opacity: showAnimations ? 1 : 0, transform: showAnimations ? 'translateX(0px)' : 'translateX(1000px)' },
    //     config: { duration: 1000 },
    // });

    // const fadeInRightAnimation = useSpring({
    //     from: { opacity: 0, transform: 'translateX(-1000px)' },
    //     to: { opacity: showAnimations ? 1 : 0, transform: showAnimations ? 'translateX(0px)' : 'translateX(-1000px)' },
    //     config: { duration: 1000 },
    // });

    // const leftToRightAnimation = useSpring({
    //     from: { opacity: 0, transform: 'translateY(100px)' },
    //     to: { opacity: showAnimations ? 1 : 0, transform: showAnimations ? 'translateY(0px)' : 'translateY(100px)' },
    //     config: { duration: 1000 },
    // });

    const quizQuestion = get(currentQuestion, 'question', '') ?? {}
    const quizAnswers = get(currentQuestion, 'answers', '') ?? {}

    // const handleMapClick = (e) => {
    //     setPointerPosition({
    //         x: e.nativeEvent.offsetX,
    //         y: e.nativeEvent.offsetY,
    //     });

    //     updateLocation(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    // };

    // const updateLocation = (x, y) => {
    //     // Convert position to approximate latitude and longitude based on image size
    //     const lat = (y / imageElement.current.naturalHeight) * 180;
    //     const lng = (x / imageElement.current.naturalWidth) * 360;

    //     setLocation({
    //         lat: lat.toFixed(6),
    //         lng: lng.toFixed(6),
    //     });
    //     handleClick(1, location1)
    // };


    const handleClick = (index, option) => {
        // setClicked(true);
        // setTimeout(() => setClicked(!isClicked), 300);
        setSelectedAnswers(option)
        setSelectedAnswersIndex(index)
        if (currentQuestion?.type === 'true_or_false' || currentQuestion?.type === 'poll') {
            if (gamePreviewPin?.game_mode !== "modern") {
                onShowResult(option)
                setShowAnimations(false)
            }
        }
    };

    // const handlePointerMouseMove = (e) => {
    //     if (dragging) {
    //         const rect = imageElement.current.getBoundingClientRect();
    //         const x = e.clientX - rect.left;
    //         const y = e.clientY - rect.top;

    //         setPointerPosition({
    //             x,
    //             y,
    //         });

    //         updateLocation(x, y);
    //     }
    // };
    // const handlePointerMouseDown = () => {
    //     setDragging(true);
    // };

    // const handlePointerMouseUp = () => {
    //     setDragging(false);
    // };
    //   ----------------------------drop_pin

    const imageElement = useRef(null);
    const [pointerPosition, setPointerPosition] = useState({ x: 50, y: 50 });

    // For PC
    const handlePCPointerDown = (event) => {
        event.preventDefault(); // Prevent default behavior to avoid unwanted selections
        document.addEventListener('mousemove', handlePCPointerMove);
        document.addEventListener('mouseup', handlePCPointerUp);
    };

    const handlePCPointerMove = (event) => {
        const imageRect = imageElement?.current?.getBoundingClientRect();
        let x = ((event.clientX - imageRect.left) / imageRect.width) * 100;
        let y = ((event.clientY - imageRect.top) / imageRect.height) * 100;
        x = Math.max(0, Math.min(x, 100));
        y = Math.max(0, Math.min(y, 100));
        setPointerPosition({ x, y });
        handleClick(1, { x, y });
    };

    const handlePCPointerUp = () => {
        document.removeEventListener('mousemove', handlePCPointerMove);
        document.removeEventListener('mouseup', handlePCPointerUp);
    };

    // For Mobile
    const handleMobilePointerDown = (event) => {
        event.preventDefault(); // Prevent default behavior to avoid unwanted selections
        document.addEventListener('touchmove', handleMobilePointerMove);
        document.addEventListener('touchend', handleMobilePointerUp);
    };

    const handleMobilePointerMove = (event) => {
        const touchEvent = event.touches ? event.touches[0] : event;
        const imageRect = imageElement?.current?.getBoundingClientRect();
        let x = ((touchEvent.clientX - imageRect.left) / imageRect.width) * 100;
        let y = ((touchEvent.clientY - imageRect.top) / imageRect.height) * 100;
        x = Math.max(0, Math.min(x, 100));
        y = Math.max(0, Math.min(y, 100));
        setPointerPosition({ x, y });
        handleClick(1, { x, y });
    };

    const handleMobilePointerUp = () => {
        document.removeEventListener('touchmove', handleMobilePointerMove);
        document.removeEventListener('touchend', handleMobilePointerUp);
    };

    useEffect(() => {
        const initializeImageDimensions = () => {
            if (imageElement.current && imageElement.current.complete) {
                const { width, height } = imageElement?.current?.getBoundingClientRect();
                setPointerPosition({ x: width / 2, y: height / 2 });
            }
        };

        initializeImageDimensions();

        // Add event listener for image load
        if (imageElement.current) {
            imageElement.current.addEventListener('load', initializeImageDimensions);
        }

        // Cleanup event listener
        return () => {
            if (imageElement.current) {
                imageElement.current.removeEventListener('load', initializeImageDimensions);
            }
        };
    }, []);

    useEffect(() => {
        if (currentQuestion?.type === "drop_pin") {
            setPointerPosition({ x: 50, y: 50 })
        }
    }, [currentQuestion]);

    //   ----------------------------drop_pin


    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = [...items];
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);

        setItems(newItems);
        setSelectedAnswers(newItems);
    };


    const getOptionClassName = (index) => {
        const colorClasses = ['Fst', 'Sec', 'Thrd', 'Frth', 'Fst', 'Sec']; // Add more colors if needed
        const colorIndex = index % colorClasses?.length;
        return `col teaQuesAns${colorClasses[colorIndex]} custmBrdrRound  mb-12 item ${index % 2 === 0 ? 'evens' : 'odds'}`;
    };


    const onShowResult = (selectedAns, isAsked) => {
        setShowStudentWait(false)
        setShowResult(true);
        let selectedAnswer = selectedAns ? selectedAns : selectedAnswers
        let questionType = ['brainstorm', 'open_ended', 'word_cloud', 'poll', 'drop_pin']
        if (questionType.includes(currentQuestion?.type)) {
            setIsCorrect(true)
            setQuotation(getRandomCotation(true))
        }
        else {
            // const isAnswerCorrecs = quizAnswers?.answer.includes(selectedAnswer);
            // if (gamePreviewPin.game_mode === "modern") {
            //     if (currentQuestion?.type === 'true_or_false') {
            //         if (isAnswerCorrecs) {

            //         }
            //         else {

            //         }
            //     }
            // }
            if (Array.isArray(quizAnswers?.answer) || currentQuestion?.type === 'true_or_false') {
                if (gamePreviewPin.game_mode === "modern") {
                    const isAnswerCorrects = quizAnswers?.answer.includes(selectedAnswer);
                    if (isAnswerCorrects) {
                        setIsCorrect(true)
                        setQuotation(getRandomCotation(true));
                        if (gamePreviewPin.game_mode === "modern") {
                            if (isAsked === 'yes') {
                                handleButtonClick(2);
                            }
                            if (isAsked === 'maybe') {
                                handleButtonClick(1);
                            }
                        }
                    } else {
                        setIsCorrect(false)
                        setQuotation(getRandomCotation(false))
                        if (gamePreviewPin.game_mode === "modern") {
                            if (isAsked === 'yes') {
                                handleButtonClick(-1);
                            }
                            if (isAsked === 'maybe') {
                                // No need to change modernScoreNew
                            }
                        }
                    }
                    if (currentQuestion?.type === 'puzzle') {
                        const areArraysEqual = (
                            quizAnswers?.answer &&
                            selectedAnswer &&
                            quizAnswers.answer?.length === selectedAnswer?.length &&
                            quizAnswers.answer.every((answer, index) =>
                                answer.toLowerCase() === selectedAnswer[index].toLowerCase()
                            )
                        );

                        if (areArraysEqual) {
                            setIsCorrect(true)
                            setQuotation(getRandomCotation(true))
                        } else {
                            setIsCorrect(false)
                            setQuotation(getRandomCotation(false))
                        }
                    }
                }
                else {
                    const isAnswerCorrect = quizAnswers?.answer.includes(selectedAnswer);
                    if (isAnswerCorrect) {
                        setIsCorrect(true)
                        setQuotation(getRandomCotation(true));
                        if (gamePreviewPin.game_mode === "modern") {
                            if (isAsked === 'yes') {
                                handleButtonClick(2);
                            }
                            if (isAsked === 'maybe') {
                                handleButtonClick(1);
                            }
                        }
                    } else {
                        setIsCorrect(false)
                        setQuotation(getRandomCotation(false))
                        if (gamePreviewPin.game_mode === "modern") {
                            if (isAsked === 'yes') {
                                handleButtonClick(-1);
                            }
                            if (isAsked === 'maybe') {
                                // No need to change modernScoreNew
                            }
                        }

                    }
                }
            } else {
                if (selectedAnswer === quizAnswers?.answer) {
                    setIsCorrect(true)
                    setQuotation(getRandomCotation(true))
                } else {
                    setIsCorrect(false)
                    setQuotation(getRandomCotation(false))
                }
            }
        }

        if (gamePreviewPin.game_mode === "modern") {
            setmodernScoreConfirm(false);
        }
    }

    const startAnimations = () => {
        setShowAnimations(true);
    };
    useEffect(() => {
        // setTimeModern(gamePreviewPin?.games_metadata?.modernTimeLimit);
        startAnimations();
    }, []);


    const [state, toggle] = useState(true)
    const { x } = useSpring({
        from: { x: 0 },
        x: state ? 1 : 0,
        config: { duration: 1000 },
    })

    const onStudentEnd = () => {
        if (currentQuestionIndex === GameQuestions?.length - 1) {
            setGameEnd(true);
            return
        }
        setShowStudentWait(true)
        setShowResult(true)
    };



    useEffect(() => {
        if (gamePreviewPin.game_mode === "modern" && gamePreviewPin?.games_metadata?.gameChanceType !== 'EndGame') {
            if (GameQuestions?.length) {
                if (currentQuestionIndex === GameQuestions?.length) {
                    nextSectionModern()
                }
            }
        } else {
            if (GameQuestions?.length) {
                if (currentQuestionIndex === GameQuestions?.length) {
                    onShowResult();
                    setGameEnd(true);
                    enjoyingGameScreen();
                }
            }
        }
    }, [currentQuestionIndex])

    useEffect(() => {
        if (currentQuestion) {
            if (showMessage === true) {
                setTimeout(() => {
                    setShowMessage(false);
                }, 2500);
            }
            setQuestionStart(false)

            if (currentQuestion?.type !== 'informational_page') {
                if (currentQuestion?.type === 'puzzle') {
                    setItems(quizAnswers.description);
                    setItemsPuzzleAnswers(quizAnswers?.description)
                }
                if (currentQuestion?.type === 'drop_pin') {

                    setImage(get(currentQuestion, 'question.imageUrl', ''));
                }
                const questionTime = get(currentQuestion, 'question.time', '');
                switch (questionTime) {
                    case '20 seconds':
                        setTime(20);
                        break;
                    case '30 seconds':
                        setTime(30);
                        break;
                    case '1 minute':
                        setTime(60);
                        break;
                    case '1 minute 30 seconds':
                        setTime(90);
                        break;
                    case '2 minutes':
                        setTime(120);
                        break;
                    case '3 minutes':
                        setTime(180);
                        break;
                    case '4 minutes':
                        setTime(240);
                        break;
                    default:
                        setTime(20);
                }
            } else {
                setTime(0);
            }
        }
    }, [currentQuestion, showMessage]);

    const timeConvertIntoSeconds = (currentQuestionTime) => {
        const questionTime = get(currentQuestionTime, 'question.time', '');
        switch (questionTime) {
            case '20 seconds':
                setTime(20);
                break;
            case '30 seconds':
                setTime(30);
                break;
            case '1 minute':
                setTime(60);
                break;
            case '1 minute 30 seconds':
                setTime(90);
                break;
            case '2 minutes':
                setTime(120);
                break;
            case '3 minutes':
                setTime(180);
                break;
            case '4 minutes':
                setTime(240);
                break;
            default:
                setTime(20);
        }
        setQuestionPage('quizQuestion');
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime === 0 && questionstart) {

                    clearInterval(countdownInterval);
                    if (currentQuestion && Object.keys(currentQuestion)?.length > 0 && !showResult) {
                        onShowResult()
                        setQuestionStart(false)

                    }
                    return 0;
                } else {
                    setQuestionStart(true)
                    // playTipSound();
                    return prevTime - 1;
                }
            });
        }, 1000);

        return () => clearInterval(countdownInterval);

    }, [time]);


    // const callbacks = {
    //     getWordColor: word => {
    //         // You can customize the color logic based on the word value
    //         const colorScale = ["#644c54", "#a6230d", "#c17914", "#a6230d", "#540405"];
    //         const index = Math.min(word.value, colorScale.length - 1);
    //         return colorScale[index];
    //     },
    //     getWordTooltip: word => `${word.text} (${word.value})`,
    // }

    // const props = useSpring({
    //     transform: 'scale(1.5) translateY(-50px)',
    //     config: { duration: 200 }
    // });

    // const getCallback = (callback, props) => {
    //     return (word, event) => {
    //         // const isActive = callback !== "onWordMouseOut";
    //         // const element = event.target;
    //         // const text = element;

    //         // text.style.cursor = isActive ? 'pointer' : 'default';
    //         // text.style.fontSize = isActive ? '500%' : '300%';
    //         // text.style.textDecoration = isActive ? 'underline' : 'none';
    //         const isActive = callback !== "onWordMouseOut";
    //         const element = event.target;
    //         const text = element;
    //         text.style.cursor = isActive ? 'pointer' : 'default';
    //         // text.style.fontSize = isActive ? '500%' : '300%';
    //         text.style.textDecoration = isActive ? 'underline' : 'none';
    //         text.animate(props);
    //     };
    // };

    const callbacks = {
        // getWordColor: word => {
        //     const colorScale = ["#7c6434", "#510706", "#a6280d"];
        //     const index = Math.min(word.value, colorScale.length - 1);
        //     return colorScale[index];
        // },
        getWordTooltip: () => null,
        onWordClick: () => null,
        onWordMouseOut: () => null,
        onWordMouseOver: () => null,
        getWordSize: word => {
            // Make all words that appear more than once bigger
            if (word.value > 1) {
                return word.value * 1.5; // Adjust the multiplier as needed
            } else {
                return word.value;
            }
        }
    }

    const onShowNext = (value) => {
        if (value === 'showChart') {
            setShowChart(true);
            // setShowStudentWait(false)
            // setShowResult(true)
        } else if (value === 'showScore') {
            setShowChart(false)
            setShowScore(true)
        }
        setShowQuestion(false)
    }
    const nextSection = (question_id) => {
        setShowResult(false)
        if (currentQuestionIndex < GameQuestions?.length) {
            const nextQestionIndex = question_id ? findIndex(GameQuestions, ['id', question_id]) : currentQuestionIndex + 1;
            const question = GameQuestions[nextQestionIndex] || {};
            studentCountDown();
            setActiveQuestion_id(question?.id);
            setCurrentQuestionIndex(nextQestionIndex);
            setShowAnimations(true);
            setGameEnd(false);
        }
        else {
            setFinalResult(true);
            setShowScore(true)
            setGameEnd(true);
            setShowQuestion(true);
            if (finalResult) {
                if (nosotros) {
                    onBackClick();
                }
                isClose(false);
            }
        }
        setSelectedAnswers('')
        setSelectedAnswersIndex();
    };

    const nextSectionModern = (question_id) => {
        setShowResult(false)
        let nextQuestionIndex;
        if (question_id) {
            nextQuestionIndex = findIndex(GameQuestions, ['id', question_id]);
        } else {
            nextQuestionIndex = currentQuestionIndex + 1;
            if (nextQuestionIndex >= GameQuestions.length) {
                nextQuestionIndex = 0;
            }
        }
        const question = GameQuestions[nextQuestionIndex] || {};
        studentCountDown();
        setActiveQuestion_id(question?.id);
        setCurrentQuestionIndex(nextQuestionIndex);
        setShowAnimations(true);
        setGameEnd(false);
        setSelectedAnswers('')
        setSelectedAnswersIndex();
    };

    useEffect(() => {
        const topLogoBack = setTimeout(() => {
            setTopLogoBack(false);
            setTimeout(() => {
                setTopLogoBack(true);
            }, 5000);
        }, 1300);

        const topLogoText = setTimeout(() => {
            setTopLogoText(false);
            setTimeout(() => {
                setTopLogoText(true);
            }, 5000);
        }, 1500);

        const topLogoTextSec = setTimeout(() => {
            setTopLogoTextSec(false);
            setTimeout(() => {
                setTopLogoTextSec(true);
            }, 5000);
        }, 1400);

        const showTimeout = setTimeout(() => {
            setShowText(true);
        }, 1800);

        const hideTimeout = setTimeout(() => {
            setShowText(false);
        }, 7000);

        const gameImage = setTimeout(() => {
            setGmeGifImage(false);
            setTimeout(() => {
                setGmeGifImage(true);
            }, 4800);
        }, 1300);

        // <---------------Second-------------->

        const textTwo = setTimeout(() => {
            setShowTextTwo(false);
            setTimeout(() => {
                setShowTextTwo(true);
            }, 5000);
        }, 1100);

        const sideImagePart = setTimeout(() => {
            setSideImgSec(false);
            setTimeout(() => {
                setSideImgSec(true);
            }, 5000);
        }, 1200);

        return () => clearTimeout(topLogoBack, topLogoText, topLogoTextSec, gameImage, showTimeout, hideTimeout, textTwo, sideImagePart);
    }, []);

    // <---------------- Top Logo BackGround animation-------------------->

    // <------------------------------------------------End Second page animation----------------------------------------------------------->


    // <------------------------------------------------Third page animation----------------------------------------------------------->

    // const winnerBoxData = [
    //     {
    //         id: 1,
    //         imageSrc: require('../../../IPlayGame/utils/assets/images/oneno.png'),
    //         name: 'Mario López'
    //     },
    //     {
    //         id: 2,
    //         imageSrc: require('../../../IPlayGame/utils/assets/images/twono.png'),
    //         name: 'Mario López'
    //     },
    //     {
    //         id: 3,
    //         imageSrc: require('../../../IPlayGame/utils/assets/images/threeno.png'),
    //         name: 'Mario López'
    //     },
    //     // Add more objects if needed
    // ];

    // const winerResult = useTrail(winnerBoxData.length, {
    //     from: { opacity: 0, y: 20 },
    //     to: { opacity: showTextsResult ? 1 : 0, y: showTextsResult ? 0 : 20 },
    //     config: { tension: 200, friction: 25 },
    //     delay: 1200,
    // });

    useEffect(() => {
        const timerResult = setTimeout(() => {
            setShowTextsResult(false);
        }, 5000);

        return () => clearTimeout(timerResult);
    }, []);

    const playerResult = [{
        no_response: 100,
        correct_response: 30,
        in_correct_response: 50
    }]

    // const [submitted_data, set_submitted_data] = useState({
    //     no_response: 0,
    //     correct_response: 0,
    //     in_correct_response: 20
    // })

    useEffect(() => {
        let no_response = 0;
        let correct_response = 0;
        let in_correct_response = 0;
        let total = 0;
        forEach(playerResult, res => {
            total++;
            const ans = find(res.submission, ["questionId", active_question_id])
            if (!ans || !ans.answer) {
                no_response++;
                return;
            } else if (ans.is_correct) {
                correct_response++;
            } else {
                in_correct_response++;
            }
        })
        // set_submitted_data({
        //     no_response: 20,
        //     correct_response: 30,
        //     in_correct_response: 20
        //     // no_response: Math.round(no_response / total * 100),
        //     // correct_response: Math.round(correct_response / total * 100),
        //     // in_correct_response: Math.round(in_correct_response / total * 100)
        // })
    }, [playerResult, active_question_id])

    const onNextAfterQuestion = () => {
        const q_type = currentQuestion?.type
        switch (q_type) {
            case 'informational_page':
                setShowScore(false);
                setShowQuestion(true);
                nextSection()
                break;
            case 'drop_pin':
            case 'open_ended':
            case 'brainstorm':
            case 'word_cloud':
            case 'type_answer':
            case 'poll':
                onShowNext('showScore')
                break;
            default:
                onShowNext('showChart')
        }
    }

    const onBackClick = () => {
        if (window !== window.top) {
            window.parent.postMessage({ action: 'close_one_game' }, '*');
        }
    }
    const ExitButton = useCallback(() => {
        return (<div className='teacherVwNextBtn teacherVwBkBtn' >

            <button class="btn-31" onClick={() => {
                if (nosotros) {
                    onBackClick();
                }
                else {
                    isClose(false);
                }
            }}
            >
                <span class="text-container">
                    <span class="text">Exit!</span>
                </span>
            </button>
        </div>)
    }, []);
    const ExitButtonModern = useCallback(() => {
        return (<label className='modernIGameLogoslbl cursorPointerEnd' onClick={() => {
            if (nosotros) {
                onBackClick();
            }
            else {
                isClose(false);
            }
        }}>
            Exit Game
        </label>)
    }, []);
    const ExitButtonWaiting = useCallback(() => {
        return (<div className='teacherVwNextBtn teacherVwBkBtn mb-6 me-6'>

            <button class="btn-31" onClick={() => {
                if (nosotros) {
                    onBackClick();
                }
                else {
                    isClose(false);
                }
            }}
            >
                <span class="text-container">
                    <span class="text">Exit!</span>
                </span>
            </button>

            {/* <Button
            onClick={() => {
                if (nosotros) {
                    onBackClick();
                }
                else {
                    isClose(false);
                }
            }}
            >Exit!</Button> */}



        </div>)
    }, []);
    const ExitButtonPreview = useCallback(() => {
        return (<div className='teacherVwNextBtn teacherVwBkBtn exitPrv'>

            <button class="btn-31" onClick={() => {
                if (nosotros) {
                    onBackClick();
                }
                else {
                    isClose(false);
                }
                setBestPlayerScrn(true);
            }}
            >
                <span class="text-container">
                    <span class="text">Exit!</span>
                </span>
            </button>

            {/* <Button
            onClick={() => {
                if (nosotros) {
                    onBackClick();
                }
                else {
                    isClose(false);
                }
                setBestPlayerScrn(true);
                }}
                >Exit Game!</Button> */}


        </div>)
    }, []);

    const [randomContent, setRandomContent] = useState();

    const textareaRef = useRef(null);
    const [dropdownVisible0, setDropdownVisible0] = useState(false);

    const toggleDropdown = (position) => {
        if (position === 0) {
            setDropdownVisible0(!dropdownVisible0);

        }
    }

    const handleBlur1 = () => {
        setDropdownVisible0(false);
    }
    const insertAccent = (accent, type) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const newInputValue =
            selectedAnswers.substring(0, startPos) +
            accent +
            selectedAnswers.substring(endPos);
        // { type === "word_cloud" ? setSelectedAnswers(newInputValue.substring(0, 20)) : setSelectedAnswers(newInputValue.substring(0, 130)); }
        { type === "word_cloud" ? setSelectedAnswers(newInputValue.substring(0, 20)) : setSelectedAnswers(newInputValue.substring(0, 130)); }

        setDropdownVisible0(false);

        // Move the cursor to the right after the added accent
        const newCursorPos = startPos + accent.length;

        // Set the new cursor position immediately after the added accent
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPos, newCursorPos);
            textarea.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 130 && currentQuestion?.type !== 'word_cloud') {
            if (currentQuestion?.type === 'type_answer') {
                setSelectedAnswers(value.substring(0, 80));

            }
            else {
                setSelectedAnswers(value);
            }

        }
        if (currentQuestion?.type === 'word_cloud') {
            setInputWord(value.substring(0, 20));
            // setInputWord(value);
            setSelectedAnswers(value.substring(0, 20));
            // setSelectedAnswers(value.trim().substring(0, 20));
        }
        if (currentQuestion?.type === 'brainstorm') {
            setInputText(value);
        }
    };
    const addWord = () => {
        if (inputWord.trim() !== '') {
            // Create a new word object and add it to the words array
            const newWord = {
                text: inputWord,
                value: 1, // You can set an initial value
            };
            // setInputWord(value.trim().substring(0, 20));
            setWords([...words, newWord]);
            setInputWord(''); // Clear the input field after adding the word
        }
    };

    const addPin = () => {
        if (selectedAnswers) {

            setPin(selectedAnswers)
        }
    };

    const addBrainStorm = () => {
        if (inputText.trim() !== '') {
            setMessages([...messages, inputText]);
            setInputText('');
        }
    }

    useEffect(() => {
        // Generate random content or components here
        const randomNumber = Math.floor(Math.random() * 5); // Change 4 to the number of different screens you want
        // Based on the random number, set different content or components
        switch (randomNumber) {
            case 0:
                setRandomContent(<div className='studentEndWrap'>
                    <div className='row g-0'>
                        <div className='col-11 col-sm-11 col-md-10 col-lg-7 stdntCardUpr stdntCardUprss stdntCardUpr stdntCardUprssNew'>
                            <div>
                                <div className='row stdntCardWnrRwCstm'>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <div>
                                            <div className='gameLogo newLogos'>
                                                {/* <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a> */}
                                                <div className='gameLogo waitingScreenLogo noCursor'>
                                                    {/* <animated.div className="gameWatingLogo" style={slideAnimationTopLogo}></animated.div>
                                                    <div>
                                                        <animated.div className="topLogoTxtPart" style={slideAnimationLogoTxt}><span>1Game</span></animated.div>
                                                        <animated.div className="topLogoSecondTxt" style={slideAnimationLogoTxtSec}><span>\</span></animated.div>
                                                    </div> */}
                                                    <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                </div>
                                            </div>
                                            <animated.div className='spanishTextGame spanishTextGames'>
                                                <p>¡Se nos acabó!</p>
                                                <p>el juego,mijo!</p>
                                            </animated.div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <div className='consoleGame'>
                                            <img src={require('@IPlayGame/utils/assets/images/consolegame.gif')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
                break;
            case 1:
                setRandomContent(
                    <div className='studentEndUprNew'>
                        <div className='studentEndWrap'>
                            <div className='row g-0'>
                                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdntCardUprss'>
                                    <div>
                                        <div className='row stdntCardWnrRwCstm'>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div>
                                                    <div className='gameLogo newLogos noCursor'
                                                    // style={LogoProps}
                                                    >
                                                        <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                    </div>
                                                    <div className='spanishTextGame spanishTextGames' >
                                                        <p>¡Nos vemos </p>
                                                        <p>en el otro</p>
                                                        <p>juego!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div className='smileImg'>
                                                    <img src={require('@IPlayGame/utils/assets/images/img42.gif')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 2:
                setRandomContent(
                    <div className='studentEndUprNew'>
                        <div className='studentEndWrap'>
                            <div className='row g-0'>
                                <div className='col-11 col-sm-11 col-md-10 col-lg-7 stdntCardUpr stdntCardUprss'>
                                    <div>
                                        <div className='row stdntCardWnrRwCstm'>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div>
                                                    <div className='gameLogo newLogos'>
                                                        <div className='gameLogo waitingScreenLogo'>
                                                            <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                        </div>
                                                    </div>
                                                    <div className='spanishTextGame spanishTextGames'>
                                                        <p>¡Se nos acabó</p>
                                                        <p>el juego...</p>
                                                        <p>SIUUUU!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div className='windowsSections windowsSectionsPrvw'>
                                                    <div>
                                                        <img
                                                            className='playr playrs'
                                                            src={require('@IPlayGame/utils/assets/images/playerfiles.png')}
                                                            alt=''
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 3:
                setRandomContent(
                    <div className='studentEndUprNew'>
                        <div className='studentEndWrap'>
                            <div className='row g-0'>
                                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdntCardUprss'>
                                    <div>
                                        <div className='row wnrScrnRwSpc'>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div>
                                                    <div className='gameLogo newLogos noCursor'>
                                                        <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                    </div>
                                                    <div className='spanishTextGame spanishTextGames'>
                                                        <p>¡Hasta la</p>
                                                        <p>vista che!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div className='runningBoy'>
                                                    <img className='bounce-in-right' src={require('@IPlayGame/utils/assets/images/running.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 4:
                setRandomContent(
                    <div className='studentEndUprNew'>
                        <div className='studentEndWrap'>
                            <div className='row g-0'>
                                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdntCardUprss'>
                                    <div>
                                        <div className='row wnrScrnRwSpc'>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div>
                                                    <div className='gameLogo newLogos noCursor'>
                                                        <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                    </div>
                                                    <div className='spanishTextGame spanishTextGames'>
                                                        <p>Se acabó...</p>
                                                        <p>¡Nos vemos</p>
                                                        <p>en la cancha</p>
                                                        <p>cuate!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div className='FootBallBouning'>
                                                    <img className='bounce2' src={require('@IPlayGame/utils/assets/images/footballBounce.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 5:
                setRandomContent(
                    <div className='studentEndUprNew'>
                        <div className='studentEndWrap'>
                            <div className='row g-0'>
                                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdntCardUprss'>
                                    <div>
                                        <div className='row wnrScrnRwSpc'>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div>
                                                    <div className='gameLogo newLogos noCursor' >
                                                        <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                    </div>
                                                    <div className='spanishTextGame spanishTextGames'>
                                                        <p>¡Hasta</p>
                                                        <p>pronto tío!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <div className='smileImg'>
                                                    <img className='bounce-in' src={require('@IPlayGame/utils/assets/images/bulb.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            default:
                setRandomContent(<div className='studentEndWrap'>
                    <div className='row g-0'>
                        <div className='col-11 col-sm-11 col-md-10 col-lg-7 stdntCardUpr stdntCardUprss stdntCardUpr stdntCardUprssNew'>
                            <div>
                                <div className='row stdntCardWnrRwCstm'>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <div>
                                            <div className='gameLogo newLogos'>
                                                {/* <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a> */}
                                                <div className='gameLogo waitingScreenLogo noCursor'>
                                                    {/* <animated.div className="gameWatingLogo" style={slideAnimationTopLogo}></animated.div>
                                                    <div>
                                                        <animated.div className="topLogoTxtPart" style={slideAnimationLogoTxt}><span>1Game</span></animated.div>
                                                        <animated.div className="topLogoSecondTxt" style={slideAnimationLogoTxtSec}><span>\</span></animated.div>
                                                    </div> */}
                                                    <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                </div>
                                            </div>
                                            <animated.div className='spanishTextGame spanishTextGames'>
                                                <p>¡Se nos acabó!</p>
                                                <p>el juego,mijo!</p>
                                            </animated.div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <div className='consoleGame'>
                                            <img src={require('@IPlayGame/utils/assets/images/consolegame.gif')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
        }
    }, []);

    const studentCountDown = () => {
        setTimeout(() => {
            setLoadingTeacher(true);
        }, 6000);
    };

    const trophyImages2 = [
        require('../../utils/assets/images/Trophy1.png'),
        require('../../utils/assets/images/Trophy2.png'),
        require('../../utils/assets/images/Trophy3.png'),
        // Add more trophy images as needed
    ];

    const trophyImages1 = [
        require('../../utils/assets/images/award1.png'),
        require('../../utils/assets/images/award2.png'),
        require('../../utils/assets/images/award3.png'),
        // Add more trophy images as needed
    ];

    const animateSection = (delay) => {
        return {
            from: { opacity: 0, transform: 'translateY(-100px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
            config: { tension: 80, friction: 80 },
            delay: delay || 0,
        };
    };

    const CupWinerProps = useSpring(animateSection(0));
    const CupWineNumberProps = useSpring(animateSection(0));
    const CupWineTitlerProps = useSpring(animateSection(0));

    const CupWinerProps2 = useSpring(animateSection(500));
    const CupWineNumberProps2 = useSpring(animateSection(500));
    const CupWineTitlerProps2 = useSpring(animateSection(500));

    const CupWinerProps3 = useSpring(animateSection(1000));
    const CupWineNumberProps3 = useSpring(animateSection(1000));
    const CupWineTitlerProps3 = useSpring(animateSection(1000));

    const ZoomBounceProps = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
    });

    const CloseImgProps = useSpring({
        from: { opacity: 0, transform: 'translateX(-100px)' },
        to: { opacity: 1, transform: 'translateX(0)' },
        config: { tension: 200, friction: 60 },
    });

    const LogoProps = useSpring({
        from: { opacity: 0, transform: 'translateY(100px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 200, friction: 60 },
    });

    const WinerNumberProps = useSpring({
        from: { opacity: 0, transform: 'translateY(-100px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 80, friction: 80 },
    });

    const WinerTitleProps = useSpring({
        from: { opacity: 0, transform: 'translateY(-100px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 200, friction: 80 },
    });

    const AwardImgProps = useSpring({
        from: { opacity: 0, transform: 'translateY(100px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 200, friction: 80 },
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            ZoomBounceProps.opacity = 1;
            ZoomBounceProps.transform = 'scale(1)';

            CupWinerProps.opacity = 1;
            CupWineNumberProps.opacity = 1;
            CupWineTitlerProps.opacity = 1;

            CupWinerProps2.opacity = 1;
            CupWineNumberProps2.opacity = 1;
            CupWineTitlerProps2.opacity = 1;

            CupWinerProps3.opacity = 1;
            CupWineNumberProps3.opacity = 1;
            CupWineTitlerProps3.opacity = 1;
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [
        CupWinerProps,
        CupWineNumberProps,
        CupWineTitlerProps,
        CupWinerProps2,
        CupWineNumberProps2,
        CupWineTitlerProps2,
        CupWinerProps3,
        CupWineNumberProps3,
        CupWineTitlerProps3,
        ZoomBounceProps,
    ]);

    const ZoomBounceWinner3 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 400,
    });
    const ZoomBounceButton3 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 1000,
    });
    const ZoomBounceButtonTitle3 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 1500,
    });
    const ZoomBounceCup3 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 2500,
    });

    const ZoomBounceWinner2 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 3000,
    });
    const ZoomBounceButton2 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 3600,
    });
    const ZoomBounceButtonTitle2 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 4100,
    });
    const ZoomBounceCup2 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 5100,
    });


    const ZoomBounceWinner1 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 5600,
    });
    const ZoomBounceButton1 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 6200,
    });
    const ZoomBounceButtonTitle1 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 6700,
    });
    const ZoomBounceCup1 = useSpring({
        from: { opacity: 0, transform: 'scale(40)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: config.wobbly,
        delay: 7700,
    });

    const generateRandomPageIndex = () => {
        const newIndex = Math.floor(Math.random() * 3);
        setRandomPageIndex(newIndex);
    };
    useEffect(() => {
        generateRandomPageIndex(); // Generate a random page index on initial render
    }, []);



    const wordCloudRender = useMemo(() => {
        const size = [400, 400];

        return (
            <ReactWordcloud
                words={words}
                size={size}
                callbacks={callbacks}
                options={optionsCloud}
            />
        )
    }, [words])

    const BrainstromRender = ({ item, ind }) => {
        return (
            <Col xs={12} md={8} lg={6} key={ind} data-tooltip-id={`my-tooltip-${ind}`}>
                <Card
                    hoverable={false}
                    className='cardBgPlayer'
                >
                    <div className='PlayerConatinerSection'>
                        <Meta
                            title={<span>{item ?? 'New words'}</span>}
                        />
                    </div>
                </Card>
                <ReactTooltip
                    id={`my-tooltip-${ind}`}
                    place="bottom"
                    content={`${item}`}
                />
            </Col>
        )
    }
    const [showGameReslt, setShowGameReslt] = useState(false);
    let highestScorer = null;

    const handleEndGame = () => {
        setShowGameResult(true);
        setShowGameReslt(true); // Show the game result
        // Add a class to the div element
        const divElement = document.querySelector('.mdrntyp');
        if (divElement) {
            divElement.classList.add('mdrnResultSec');
        }

        //   isClose(false);
        setTimeout(() => {
            if (nosotros) {
                onBackClick();
            } else {
                isClose(false);
            }
        }, 10000);

        const randomNums = [];

        // Generate a random number between 0 and 5 for each player
        for (let i = 0; i < showPlayerResultStc.length; i++) {
            randomNums.push(Math.floor(Math.random() * 6));
        }
        const updatedPlayers = showPlayerResultStc.map((player, index) => (
            {
                ...player,
                modernFinalScore: player.modernFinalScore + randomNums[index]
            }));
        setShowPlayerResultStc(updatedPlayers);
        const highestScorer = updatedPlayers.reduce((prev, current) => {
            return (prev.modernFinalScore > current.modernFinalScore) ? prev : current;
        });
        setHighestScor(highestScorer);
        onShowResult();
        setGameEnd(true);
        enjoyingGameScreen();
    };

    const convertNumberToWord = (number) => {
        const words = ['First place!', 'Second place!', 'Third place!', 'Fourth place', 'Fifth place']
        return words[number - 1] || number.toString()
    }

    let monsterList = avtarData;

    useEffect(() => {
        if (timeModern === 0) {
            handleEndGame();
        }
        const interval = setInterval(() => {
            if (timeModern > 0) {
                setTimeModern(timeModern - 1)
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, [timeModern]);
    const modernImageItem = modernImage && modernImage.length > 0 ? modernImage[currentImageIndex] : {};
    const { imageUrl, inAnimation, outAnimation, inSound } = modernImageItem || {};

    const backgroundColors = ['#00d64f', '#ffae00', '#ed2b7f', '#7a29c2', '#000000'];
    const backgroundModernPart = ['#ffae00', '#ed2b7f', '#7a29c2', '#000000'];
    const sortedStudents = showPlayerResult.slice().sort((a, b) => b.modernFinalScore - a.modernFinalScore);
    const top5Students = sortedStudents.slice(0, 5);
    const { currentGameSession } = useCurrentGameSession()
    const [positionIndex, setPositionIndex] = useState(3)

    useEffect(() => {
        if (!showGameResult) {
            const intervalId = setInterval(() => {
                setPositionIndex((prevIndex) => (prevIndex + 1) % 5)
            }, 5000)
            return () => clearInterval(intervalId)
        }
    }, [])

    const modernPreClsNames = [
        'modernPreviewFstGme',
        'modernPreviewSecGme',
        'modernPreviewThirdGme',
        'modernPreviewFourthGme',
        'modernPreviewFifthGme',
    ];



    return (
        <div className='setPrVwAddon'>
            <Drawer
                closable={false}
                onClose={isClose}
                open={isOpen}
                width="100%"
                zIndex={9999}
                className='gameDevicemMain'
            >
                <Row className='gameOnDevice'>
                    {/*---------------- teacher end left section -----------------*/}
                    <Col xs={24} md={24} lg={12}>
                        <div className='setPrVwHght'>
                            {questionPage === 'waiting' && (
                                <div className="studentEndWatingSec 666">
                                    <div className='row g-0'>
                                        <div className='col-11 col-sm-11 col-md-11  waitingScreenUpr'
                                        // style={{ minHeight: '82vh' }}
                                        >
                                            <div className='allWatingSection'>
                                                <div className='gameLogo waitingScreenLogo mt-5'>
                                                    <a>
                                                        <img src={require('../../utils/assets/images/GameLogo.jpg')}
                                                            alt="" />
                                                    </a>
                                                </div>
                                                <div className='waitingMidSec waitingQrMidSection QRContCode minmarLogo'>

                                                    <div className="mainQrSection removeMargin">
                                                        <QRCode
                                                            value={'Preview Section'}
                                                            bgColor="#fff" className="qrCodeMngWdthPreview" />
                                                    </div>
                                                    {gamePreviewPin?.pin_code && (
                                                        <div className="watingScreenQrSection">
                                                            <div className='waitingTxtGme'><span>CÓDIGO:</span></div>
                                                            <div className="waitingGmeQrCode">
                                                                <span style={{ color: "black" }}>{get(gamePreviewPin, 'pin_code', '') || ''}</span>
                                                                <span style={{ color: "black" }}><LogoutOutlined /></span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='waitingLstSec'>
                                                    <label><div>Waiting for players...</div></label>
                                                </div>
                                                {/* <div className="strtBtnWtingScreen"
                                                    onClick={() => {
                                                        if (GameQuestions?.length > 0) {
                                                            timeConvertIntoSeconds(currentQuestion);
                                                            studentCountDown();
                                                        }
                                                    }}
                                                >
                                                    <Button
                                                        loading={start_loader}
                                                    >Start</Button>
                                                </div> */}
                                                <div className="" style={{ display: "flex", justifyContent: "center" }}>
                                                    {/* <Button disabled={users.length === 0} onClick={nextSection} loading={start_loader}>Start</Button> */}
                                                    <div className="StartBtnNew">
                                                        <Button disabled={users.length < 6} loading={start_loader} onClick={() => {
                                                            if (GameQuestions?.length > 0) {
                                                                timeConvertIntoSeconds(currentQuestion);
                                                                studentCountDown();
                                                            }
                                                            if (gamePreviewPin?.games_metadata?.gameChanceType === 'limited') {
                                                                setTimeModern(gamePreviewPin?.games_metadata?.modernTimeLimit * 60)
                                                            }
                                                            const animatedImages = getModernImage(gamePreviewPin?.modern_type);
                                                            setModernImage(animatedImages)
                                                        }} >
                                                            <p>Start</p>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='allPlayerEnterPart' style={{ position: 'relative' }}>
                                        <UserList
                                            users={users}
                                        />
                                        <ExitButtonWaiting />
                                    </div>
                                </div>
                            )}
                            {questionPage === 'quizQuestion' && (
                                <div className={`fullbgonegame ${gamePreviewPin.game_mode === "classic" ? 'studentEndUpr' : 'modernResultSection moderntyp'}`}>
                                    <>
                                        <> {gamePreviewPin.game_mode === "modern" ? (
                                            <>
                                                <div className='mdrntyp'>
                                                    <div className='modernIGameLogos timerMdernDisplay modernGmePreview'>
                                                        <img src={IGameLogos} className='igamelogo' alt='' />
                                                        {gamePreviewPin?.games_metadata?.gameChanceType === 'limited' && (
                                                            <div className='quesTimer'>
                                                                <label className='quesAnsTimerCount teacherTimerModern'>{timeModern}</label>
                                                            </div>
                                                        )}
                                                        <span>Players: {users.length}</span>
                                                    </div>
                                                    <div className='soccerImg soccerImgPreview'>
                                                        <div className={gamePreviewPin?.modern_type === 'park' ? 'modernParkPreSection' : 'allModernSection'} style={{ top: '50%', zIndex: 9999 }}>
                                                            {Object.values(gamePreviewPin)?.length > 0 &&
                                                                top5Students.map((player, index) => {
                                                                    let rank = rankposition.indexOf(player?.modernFinalScore)
                                                                    let postionName = convertNumberToWord(index + 1)
                                                                    return (
                                                                        <div style={{ height: 50 }}>
                                                                            {gamePreviewPin?.modern_type !== 'park' ? (
                                                                                <ModernSection
                                                                                    className='modernMidImg box'
                                                                                    key={index}
                                                                                    player={player}
                                                                                    rank={rank}
                                                                                    size={size}
                                                                                >
                                                                                    <>
                                                                                        <div className='playerNumberRankModern'>
                                                                                            <span style={{ backgroundColor: backgroundColors[index] }}>{index + 1}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerName modernPlayerNamePreview'>
                                                                                            <span>{player?.user_name}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerRank avatarSizeMoldern'>
                                                                                            <Lottie
                                                                                                className='avatarSelectModern avatarSelectModernPreview'
                                                                                                animationData={
                                                                                                    monsterList?.find((items) => items.name === player?.avatarImg)
                                                                                                        ?.imgs
                                                                                                }
                                                                                            />
                                                                                            <p>

                                                                                                {
                                                                                                    player?.modernFinalScore
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='soccerFootballModern'>
                                                                                            {gamePreviewPin?.modern_type === 'magic_goal' && (
                                                                                                <img src={SoccerFootball} alt='soccer' />
                                                                                            )}
                                                                                            {(gamePreviewPin?.modern_type === 'king_jump' ||
                                                                                                gamePreviewPin?.modern_type === 'park') && (
                                                                                                    <img
                                                                                                        src={require('../../utils/assets/images/volleyball_football.png')}
                                                                                                        alt=''
                                                                                                    />
                                                                                                )}
                                                                                            {gamePreviewPin?.modern_type === 'run' && (
                                                                                                <img
                                                                                                    src={require('../../utils/assets/images/racetrack_img.png')}
                                                                                                    alt=''
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                </ModernSection>
                                                                            ) : (
                                                                                <div key={index} className={modernPreClsNames[index]}>
                                                                                    <div className='modernMidImg'>
                                                                                        <div className='playerNumberRank'>
                                                                                            <span style={{ backgroundColor: backgroundColors[index] }}>{index + 1}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerName'>
                                                                                            <span>{player.user_name}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerRank avatarSizeMoldern'>
                                                                                            <Lottie
                                                                                                className='avatarSelectModern'
                                                                                                animationData={
                                                                                                    monsterList?.find((items) => items.name === player?.avatarImg)?.imgs
                                                                                                }
                                                                                            />
                                                                                            <p>
                                                                                                {positionIndex === index
                                                                                                    ? postionName
                                                                                                    : player?.modernFinalScore}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='soccerFootball'>
                                                                                            <img
                                                                                                src={require('../../utils/assets/images/volleyball_football.png')}
                                                                                                alt=''
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                })}
                                                            {/* <div className='allModernSection' style={{ top: '50%', zIndex: 9999 }}> */}
                                                            {Object.values(gamePreviewPin)?.length > 0 &&
                                                                showPlayerResultStc.slice(0, 5).map((player, index) => {
                                                                    let rank = rankposition.indexOf(player?.modernFinalScore)
                                                                    let postionName = convertNumberToWord(index + 1)
                                                                    return (
                                                                        <div style={{ height: 85 }}>
                                                                            {gamePreviewPin?.modern_type !== 'park' ? (
                                                                                <ModernSection
                                                                                    className='modernMidImg box newSpaceMonster'
                                                                                    key={index}
                                                                                    player={player}
                                                                                    rank={rank}
                                                                                    size={size}
                                                                                >
                                                                                    <>
                                                                                        <div className='playerNumberRankModern'>
                                                                                            <span style={{ backgroundColor: backgroundModernPart[index] }}>{index + 2}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerName modernPlayerNamePreview'>
                                                                                            <span>{player?.user_name}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerRank avatarSizeMoldern'>
                                                                                            <Lottie
                                                                                                className='avatarSelectModern avatarSelectModernPreview'
                                                                                                animationData={
                                                                                                    monsterList?.find((items) => items.name === player?.avatarImg)
                                                                                                        ?.imgs
                                                                                                }
                                                                                            />
                                                                                            <p>

                                                                                                {
                                                                                                    player?.modernFinalScore
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='soccerFootballModern'>
                                                                                            {gamePreviewPin?.modern_type === 'magic_goal' && (
                                                                                                <img src={SoccerFootball} alt='soccer' />
                                                                                            )}
                                                                                            {(gamePreviewPin?.modern_type === 'king_jump' ||
                                                                                                gamePreviewPin?.modern_type === 'park') && (
                                                                                                    <img
                                                                                                        src={require('../../utils/assets/images/volleyball_football.png')}
                                                                                                        alt=''
                                                                                                    />
                                                                                                )}
                                                                                            {gamePreviewPin?.modern_type === 'run' && (
                                                                                                <img
                                                                                                    src={require('../../utils/assets/images/racetrack_img.png')}
                                                                                                    alt=''
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                </ModernSection>
                                                                            ) : (
                                                                                <div key={index} className={modernPreClsNames[index + 1]}>
                                                                                    <div className='modernMidImg'>
                                                                                        <div className='playerNumberRank'>
                                                                                            <span style={{ backgroundColor: backgroundModernPart[index] }}>{index + 2}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerName'>
                                                                                            <span>{player.user_name}</span>
                                                                                        </div>
                                                                                        <div className='modernPlayerRank avatarSizeMoldern'>
                                                                                            <Lottie
                                                                                                className='avatarSelectModern'
                                                                                                animationData={
                                                                                                    monsterList?.find((items) => items.name === player?.avatarImg)?.imgs
                                                                                                }
                                                                                            />
                                                                                            <p>
                                                                                                {positionIndex === index + 1
                                                                                                    ? postionName
                                                                                                    : player?.modernFinalScore}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='soccerFootball'>
                                                                                            <img
                                                                                                src={require('../../utils/assets/images/volleyball_football.png')}
                                                                                                alt=''
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                })}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    {!showGameReslt && (
                                                        <div className={`soccerAnimatedImg animate__animated ${fadeIn ? inAnimation : outAnimation}`}>
                                                            <img src={imageUrl} alt='' style={{width:'90%'}} className='crop-img-score' />
                                                        </div>
                                                    )}
                                                    {/* <button onClick={handleNext}>Next</button>
                                                    <button onClick={handlePrevious}>Previous</button> */}
                                                    {gamePreviewPin?.modern_type === 'magic_goal' && (
                                                        <img src={require('../../utils/assets/images/soccer_img.jpg')} className='mdrntypimg' alt='' />
                                                    )}
                                                    {gamePreviewPin?.modern_type === 'king_jump' && (
                                                        <img src={require('../../utils/assets/images/soccer_volleyball.jpg')} className='mdrntypimg' alt='' />
                                                    )}
                                                    {gamePreviewPin?.modern_type === 'run' && (
                                                        <img src={require('../../utils/assets/images/racetrack.png')} className='mdrntypimg' alt='' />
                                                    )}
                                                    {gamePreviewPin?.modern_type === 'park' && (
                                                        <img src={require('../../utils/assets/images/soccer_park.png')} className='mdrntypimg' alt='' />
                                                    )}
                                                    <label className='modernIGameLogoslbl cursorPointerEnd' onClick={handleEndGame}> End Game</label>
                                                    {showGameReslt && (
                                                        <>
                                                            <div className='goalResultImgg modernPreviewResult'>
                                                                {gamePreviewPin?.modern_type === 'magic_goal' && (
                                                                    <div className='allPartSecmy'>
                                                                        <div>
                                                                            <Lottie
                                                                                animationData={BlueMonsterdata2}
                                                                                className='anim'
                                                                            />
                                                                        </div>
                                                                        <div className='topTwoStudentNamePreview'>
                                                                            {/* <span>{highestScor ? highestScor.user_name : "A k"}</span> */}
                                                                            <span>{highestScor ? 'Dummy 1' : "A k"}</span>
                                                                        </div>
                                                                        <img src={require('../../utils/assets/images/goal_img.png')} alt='' />
                                                                        <p className="wnr" style={{ textAlign: 'center' }}>WINNER</p>
                                                                    </div>
                                                                )}
                                                                {gamePreviewPin?.modern_type === 'king_jump' && (
                                                                    <>
                                                                        <div className='allPartSecmy'>
                                                                            <div>
                                                                                <Lottie
                                                                                    animationData={BlueMonsterdata2}
                                                                                    className='anim'
                                                                                />
                                                                            </div>
                                                                            <div className='topTwoStudentNamePreview'>
                                                                                {/* <span>{highestScor ? highestScor.user_name : "A k"}</span> */}
                                                                                <span>{highestScor ? 'Dummy 1' : "A k"}</span>
                                                                            </div>
                                                                            <img src={require('../../utils/assets/images/hoops_img.png')} alt='' />
                                                                            <p className="wnr" style={{ textAlign: 'center' }}>WINNER</p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {gamePreviewPin?.modern_type === 'run' && (
                                                                    <>
                                                                        <div className='allRunPartSecmy'>
                                                                            <div>
                                                                                <Lottie
                                                                                    animationData={BlueMonsterdata2}
                                                                                    className='anim1'
                                                                                />
                                                                            </div>
                                                                            <div div className='topTwoStudentName1'>
                                                                                {/* <span>{highestScor ? highestScor.user_name : "A k"}</span> */}
                                                                                <span>{highestScor ? 'Dummy 1' : "A k"}</span>
                                                                            </div>
                                                                            <img className='resultLottieImg' src={require('../../utils/assets/images/racetrack_wins.png')} alt='' />
                                                                            <p className="wnr" style={{ textAlign: 'center' }}>WINNER</p>

                                                                        </div>
                                                                    </>
                                                                )}
                                                                {gamePreviewPin?.modern_type === 'park' && (<>
                                                                    <div className='racetractWinImg1'>
                                                                        <div>
                                                                            <Lottie
                                                                                animationData={BlueMonsterdata2}
                                                                                className='anim2'
                                                                            />
                                                                        </div>
                                                                        <div className='topTwoStudentName2'>
                                                                            {/* <span>{highestScor ? highestScor.user_name : "A k"}</span> */}
                                                                            <span>{highestScor ? 'Dummy 1' : "A k"}</span>
                                                                        </div>
                                                                        <img src={require('../../utils/assets/images/baseball_img.png')} alt='' />
                                                                        <p className="wnr2" style={{ textAlign: 'center' }}>WINNER</p>

                                                                        {/* <div>
                                                          <Lottie
                                                            animationData={BlueMonsterdata2}
                                                            className='anim2'
                                                        />
                                                        </div>
                                                        <div className='topTwoStudentName2'>
                                                            <span>{highestScorer ? highestScorer.user_name : "A k"}</span>
                                                        </div> */}


                                                                    </div>
                                                                </>
                                                                )}
                                                            </div>


                                                        </>
                                                    )}

                                                    {/* <label className='modernIGameLogoslbl cursorPointerEnd'><ExitButton /></label> */}
                                                    {/* <ExitButtonModern /> */}

                                                </div>
                                            </>
                                        ) : <>
                                            {showQuestion && gameEnd === false ? (
                                                <div className="prjctrWraper">
                                                    {loadingTeacher || currentQuestionIndex === 0 ? (
                                                        <>
                                                            <div className="quesDetails quesDetaisTr">
                                                                <div className="topTcherTxt">
                                                                    <label>{getType(get(currentQuestion, 'type', ''))}</label>
                                                                </div>
                                                                <div className="quesTimer">
                                                                    {currentQuestion?.type !== 'informational_page' ? (
                                                                        <label className="quesAnsTimerCount">{time}</label>
                                                                    ) : <label className="quesAnsZeroTimerCount" />}
                                                                </div>
                                                            </div>
                                                            <div className={`${(currentQuestion?.type === 'type_answer' || currentQuestion?.type === 'open_ended') && 'typeAnswerCenter'}`}>
                                                                <div className={`questionViewMid mb-12 text-center openBounceIn ${(currentQuestion?.type === 'type_answer' || currentQuestion?.type === 'open_ended') && 'makeBigger'}`}>
                                                                    <div className="plyOpnEnd">
                                                                        <p className='noBreakStyle'>{get(quizQuestion, 'title', '')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="teacherQuesViewRght">
                                                                <div className='card-body p-0'>
                                                                    <div className='card-p d-flex' style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                                                                        {/* {getQuestionUrl && (
                                                                        <div className='imgContainerAQ'>
                                                                            <img className='AQimage' src={getQuestionUrl} alt="Image" />
                                                                        </div>
                                                                    )} */}
                                                                        {(currentQuestion?.type === 'informational_page') ? (
                                                                            <div className='informGmsDtal'>
                                                                                {getQuestionUrl && (
                                                                                    <div className='imgContainerAQ'>
                                                                                        <img className='AQimage' src={getQuestionUrl} alt="Image" />
                                                                                    </div>
                                                                                )}
                                                                                {/* <div className="questionViewMid infoViewMid mb-12 text-center">
                                                                                <div className="plyOpnEnd"><p>{get(quizQuestion, 'title', '')}</p></div>
                                                                            </div> */}
                                                                            </div>
                                                                        ) : (
                                                                            <div className='imgContainerAQ'>
                                                                                <div className='dropPinContain' style={{ position: 'relative' }}>
                                                                                    {getQuestionUrl && <img className='AQimage' src={getQuestionUrl} alt="Image" />}
                                                                                    {(currentQuestion?.type === 'drop_pin' && pin) &&
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                left: `${pin?.x}%`,
                                                                                                top: `${pin?.y}%`,

                                                                                            }}
                                                                                            className='pinPointerImg'

                                                                                        >
                                                                                            {/* Add the pin image here */}
                                                                                            <img
                                                                                                src={require('../../utils/assets/images/pinIcon2.png')}  // Replace with the actual path to your pin image
                                                                                                alt="Pin"
                                                                                                style={{
                                                                                                    width: '30px',  // Adjust the width to match the white dot
                                                                                                    height: 'auto',
                                                                                                    transform: 'translate(-50%, 0)',
                                                                                                    position: 'absolute',
                                                                                                    bottom: '2px',  // Adjust this value to position the bottom of the pin image at the center of the white dot
                                                                                                    left: '50%',
                                                                                                    // Center the pin image horizontally
                                                                                                }}
                                                                                            />
                                                                                            {/* <div
                                                                                      style={{
                                                                                          width: '10px',
                                                                                          height: '10px',
                                                                                          borderRadius: '50%',
                                                                                          background: 'red',
                                                                                          position: 'relative',
                                                                                          zIndex: 1,  // Ensure the white circle is above the pin image
                                                                                      }}
                                                                                  ></div> */}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {currentQuestion?.type !== 'type_answer' && quizAnswers?.options?.map((option, index) => (
                                                                            <div className='position-relative addQuestionAnswerUprView ansStruct openBounceIn' key={index}>
                                                                                <div className='row g-0'>
                                                                                    <animated.div className={getOptionClassName(index)}>
                                                                                        <div className='addAnswerTchrView'>
                                                                                            <animated.div className=''>
                                                                                            </animated.div>
                                                                                            <div className=''>
                                                                                                {isImageUrl(option) ? (
                                                                                                    <>
                                                                                                        <img src={option} alt={`Option ${String.fromCharCode(65 + index)}`} className='imgContainerAQ imgContainerAQSize' />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>

                                                                                                        <label>{`(${String.fromCharCode(65 + index)}) ${option}`}</label>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            {/* {selectedAnswersIndex === index && <CheckCircleFilled className="correctTrueAns tfAnswer" />} */}
                                                                                        </div>
                                                                                    </animated.div>

                                                                                    {/* {selectedAnswersIndex === index && <CheckCircleFilled className="correctTrueAns tfAnswer" />} */}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {currentQuestion?.type === 'word_cloud' && (
                                                                            <div className="wordCloudCont" style={{ height: 400, width: '100%', justifyContent: 'center' }}>
                                                                                {/* <ReactWordcloud
                                                                                words={words}
                                                                                callbacks={callbacks}
                                                                                options={optionsCloud}
                                                                            /> */}
                                                                                {wordCloudRender}
                                                                            </div>
                                                                        )}
                                                                        {currentQuestion?.type === 'brainstorm' && (
                                                                            <div className={`allPlayerEnterPart ${currentQuestion?.type === 'brainstorm' && 'brinCard'}`}>
                                                                                <Row gutter={10} className='addingSCrlonList brainTitleCard tooltipsInfo'>
                                                                                    {map(messages, (item, index) => <BrainstromRender ind={index} item={item} />)}
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row g-0 correctNxtBtnUpr butonAdjust'>
                                                                <div className='col-12 nextBkBtnUpr'>
                                                                    <ExitButton />
                                                                    <div className='teacherVwNextBtn'>
                                                                        <button
                                                                            className={currentQuestion?.type === 'informational_page' ? '' : !showResult ? "btn-disabled" : ''}
                                                                            disabled={currentQuestion?.type === 'informational_page' ? false : !showResult}
                                                                            onClick={() => {
                                                                                setTime(0);
                                                                                if (currentQuestion?.type === 'informational_page' || showResult)
                                                                                    onNextAfterQuestion();
                                                                                setPin("")
                                                                            }}
                                                                        >Next!</button></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <WantingScreentLoader functionName={""} onExitGame={""} exitLoader={""} comesFrom="preview" nosotros={nosotros} isClose={isClose} />
                                                    )}

                                                </div>
                                            ) :
                                                <>{
                                                    gameEnd === true && (
                                                        <div className='prvwAwrdSetup'>
                                                            {/* rectangle box */}
                                                            {randomPageIndex === 0 && (
                                                                <>
                                                                    <div className='row g-0'>
                                                                        <div className='col-12 col-sm-12 col-md-12'>
                                                                            <animated.div className='podiumClose' style={CloseImgProps}>
                                                                                <ExitButtonPreview />
                                                                            </animated.div>
                                                                            <animated.div className=' podiumLogo mt-10' style={LogoProps}>
                                                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                                            </animated.div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row g-0 circlAwrdRw unsetPostion'>
                                                                        <div className='col-11 col-sm-7 col-md-6 col-lg-6'>
                                                                            <div className="cupAwrdUpr pdiumsidespace">
                                                                                <div className="cupAwrd">
                                                                                    <animated.label className="cupAwrdNum"
                                                                                        style={CupWineNumberProps}
                                                                                    >{1}</animated.label>
                                                                                    <animated.div className='cupTitleLink'
                                                                                        style={CupWinerProps}
                                                                                    >
                                                                                        <animated.label

                                                                                        >{'Dummy Student 1'}</animated.label>
                                                                                        <div className="cupAwrdImg">
                                                                                            <animated.img
                                                                                                style={ZoomBounceProps}
                                                                                                src={trophyImages2[0]}
                                                                                                alt="" />
                                                                                        </div>
                                                                                    </animated.div>
                                                                                </div>
                                                                                <div className="cupAwrd">
                                                                                    <animated.label className="cupAwrdNum"
                                                                                        style={CupWineNumberProps}
                                                                                    >{1 + 1}</animated.label>
                                                                                    <animated.div className='cupTitleLink'
                                                                                        style={CupWinerProps}
                                                                                    >
                                                                                        <animated.label
                                                                                        >{'Dummy Student 2'}</animated.label>
                                                                                        <div className="cupAwrdImg">
                                                                                            <animated.img
                                                                                                style={ZoomBounceProps}
                                                                                                src={trophyImages2[1]}
                                                                                                alt="" />
                                                                                        </div>
                                                                                    </animated.div>
                                                                                </div>
                                                                                <div className="cupAwrd">
                                                                                    <animated.label className="cupAwrdNum"
                                                                                        style={CupWineNumberProps}
                                                                                    >{2 + 1}</animated.label>
                                                                                    <animated.div className='cupTitleLink'
                                                                                        style={CupWinerProps}
                                                                                    >
                                                                                        <animated.label
                                                                                        >{'Dummy Student 3'}</animated.label>
                                                                                        <div className="cupAwrdImg">
                                                                                            <animated.img
                                                                                                style={ZoomBounceProps}
                                                                                                src={trophyImages2[2]}
                                                                                                alt="" />
                                                                                        </div>
                                                                                    </animated.div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )}
                                                            {/* circle award  */}
                                                            {randomPageIndex === 1 && (
                                                                <>
                                                                    <div className='row g-0'>
                                                                        <div className='col-12 col-sm-12 col-md-12'>
                                                                            <div className='podiumClose'>
                                                                                <ExitButtonPreview />
                                                                            </div>
                                                                            <animated.div className=' podiumLogo mt-10' style={LogoProps}>
                                                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                                            </animated.div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row g-0 circlAwrdRw unsetPostion'>
                                                                        <div className='col-11 col-sm-4 col-md-4 col-lg-4'>
                                                                            <div className="circlAwrdUpr">
                                                                                <animated.div className="circlAwrd">
                                                                                    <animated.label
                                                                                        style={WinerNumberProps}
                                                                                        className="circlAwrdNum">{1}</animated.label>
                                                                                    <animated.div className='awrdTitleLink'
                                                                                        style={WinerTitleProps}
                                                                                    >
                                                                                        <label>{'Dummy Student 1'}</label>
                                                                                    </animated.div>
                                                                                    <img src={require('../../utils/assets/images/circleIcon2.png')} alt="" className="AwrdGlob" />
                                                                                    <animated.img
                                                                                        src={trophyImages1[0]}
                                                                                        alt=""
                                                                                        className="AwrdTrphy AwrdTrphys"
                                                                                        style={AwardImgProps}
                                                                                    />
                                                                                </animated.div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-11 col-sm-4 col-md-4 col-lg-4'>
                                                                            <div className="circlAwrdUpr">
                                                                                <animated.div className="circlAwrd">
                                                                                    <animated.label
                                                                                        style={WinerNumberProps}
                                                                                        className="circlAwrdNum">{2}</animated.label>
                                                                                    <animated.div className='awrdTitleLink'
                                                                                        style={WinerTitleProps}
                                                                                    >
                                                                                        <label>{'Dummy Student 2'}</label>
                                                                                    </animated.div>
                                                                                    <img src={require('../../utils/assets/images/circleIcon2.png')} alt="" className="AwrdGlob" />
                                                                                    <animated.img
                                                                                        src={trophyImages1[1]}
                                                                                        alt=""
                                                                                        className=" AwrdTrphy AwrdTrphys"
                                                                                        style={AwardImgProps}
                                                                                    />
                                                                                </animated.div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-11 col-sm-4 col-md-4 col-lg-4'>
                                                                            <div className="circlAwrdUpr">
                                                                                <animated.div className="circlAwrd">
                                                                                    <animated.label
                                                                                        style={WinerNumberProps}
                                                                                        className="circlAwrdNum">{3}</animated.label>
                                                                                    <animated.div className='awrdTitleLink'
                                                                                        style={WinerTitleProps}
                                                                                    >
                                                                                        <label>{'Dummy Student 3'}</label>
                                                                                    </animated.div>
                                                                                    <img src={require('../../utils/assets/images/circleIcon2.png')} alt="" className="AwrdGlob" />
                                                                                    <animated.img
                                                                                        src={trophyImages1[2]}
                                                                                        alt=""
                                                                                        className=" AwrdTrphy AwrdTrphys"
                                                                                        style={AwardImgProps}
                                                                                    />
                                                                                </animated.div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {/* podium design  */}
                                                            {randomPageIndex === 2 && (
                                                                <>
                                                                    <div className='row g-0 mb-5'>
                                                                        <div className='col-12 col-sm-12 col-md-12'>
                                                                            <div className='podiumClose'>
                                                                                <ExitButtonPreview />
                                                                            </div>
                                                                            <animated.div className=' podiumLogo mt-10' style={LogoProps}>
                                                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                                                            </animated.div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='podiumOneUpr pdiumsidespace'>
                                                                        <div className='row g-0'>
                                                                            <div className='col-12 col-sm-12 col-md-12 col-lg-7 podiumOneCol'>
                                                                                <div className='podiumOne'>
                                                                                    <div className='podiumDataCmn podiumOneData'>
                                                                                        <animated.div className='podiumSetCmn podiumNumCmn' style={ZoomBounceWinner2}>2</animated.div>
                                                                                        <animated.div className='podiumColData' style={ZoomBounceButton2}>
                                                                                            <animated.label style={ZoomBounceButtonTitle2}>{'Dummy Student 1'}</animated.label>
                                                                                        </animated.div>
                                                                                        <div className="podiumColCp">
                                                                                            <animated.img src={require('../../utils/assets/images/Trophy2.png')} alt="" style={ZoomBounceCup2} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='podiumDataCmn podiumTwoData'>
                                                                                        <animated.div className='podiumColData' style={ZoomBounceButton1}>
                                                                                            <animated.label style={ZoomBounceButtonTitle1}>{'Dummy Student 2'}</animated.label>
                                                                                        </animated.div>
                                                                                        <animated.div className='podiumSetCmn podiumNumMid' style={ZoomBounceWinner1}>1</animated.div>
                                                                                        <div className="podiumColCp podiumColCpMid">
                                                                                            <animated.img src={require('../../utils/assets/images/Trophy1.png')} alt="" style={ZoomBounceCup1} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='podiumDataCmn podiumThreeData'>
                                                                                        <animated.div className='podiumSetCmn podiumNumCmn' style={ZoomBounceWinner3}>3</animated.div>
                                                                                        <animated.div className='podiumColData' style={ZoomBounceButton3}>
                                                                                            <animated.label style={ZoomBounceButtonTitle3}>{'Dummy Student 3'}</animated.label>
                                                                                        </animated.div>
                                                                                        <div className="podiumColCp">
                                                                                            <animated.img src={require('../../utils/assets/images/Trophy3.png')} alt="" style={ZoomBounceCup3} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            }
                                            {showChart && (
                                                <div>
                                                    <StudentChart playerResult={[1, 2]} active_question_id={active_question_id} />
                                                    <div className='row g-0 correctNxtBtnUpr'>
                                                        <div className='col-12 nextBkBtnUpr'>
                                                            <ExitButton />
                                                            <div className='teacherVwNextBtn previewTeacherNextBtn'><Button onClick={() => {
                                                                onShowNext('showScore');
                                                                setLoadingTeacher(false);
                                                            }}>Next!</Button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {showScore && gameEnd === false && (
                                                <ScoreDesign
                                                    currentQuestion={currentQuestion}
                                                    active_question_id={active_question_id}
                                                    exitButton={ExitButton}
                                                    onNext={() => {
                                                        setShowScore(false);
                                                        setShowQuestion(true);
                                                        nextSection();
                                                    }} />
                                            )}
                                        </>
                                        }</>
                                    </>

                                </div>
                            )}
                        </div>
                    </Col>
                    {/*------------------ student end left section-------------------- */}
                    <Col xs={24} md={24} lg={12}>
                        {questionPage === 'waiting' && (
                            <div className="studentEndWatingSec">
                                <div className='row g-0'>
                                    <div className='col-12 col-sm-12 col-md-12 waitingScreenUpr waitScren'>
                                        <div className='allWatingSection'>
                                            <div className='gameLogo waitingScreenLogo'>
                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                            </div>
                                            <div className='waitingMidSec'>
                                                <div className='monsterContainer'>
                                                    <div className='lottieContainer'>
                                                        {/* <Lottie animationData={monsterList.find((item) => item.name == selectedAvatar)?.imgs} onClick={() => setAvatModal(true)} /> */}
                                                        <Lottie
                                                            animationData={BlueMonsterdata2}
                                                        // onClick={() => setAvatModal(true)}
                                                        />
                                                    </div>
                                                    {avatarChoosed ?
                                                        <div className='btnConatinerSection'>
                                                            <Button
                                                            // onClick={() => setAvatModal(true)}
                                                            >Choose avatar </Button>
                                                        </div>
                                                        :
                                                        <div className='waitingLstSec'>
                                                            <label><animated.div>Waiting for players...</animated.div></label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {questionPage === 'quizQuestion' && (
                            <div className='setPrVwHght'>
                                <div style={{display: showResult === false && currentQuestion  ? "block" : "none"}} className={gamePreviewPin.game_mode === "modern" ? 'studentEndUprModern fullbgonegame' : 'studentEndUpr fullbgonegame'}>
                                        {/* gamePreviewPin.game_mode === "modern"  */}
                                        <div className="prjctrWraper">
                                            {loadingTeacher || currentQuestionIndex === 0 ? (
                                                <>
                                                    {modernScoreConfirm ? null :
                                                        <>
                                                            <div className="quesDetails quesDetaisTr">
                                                                <div className="topTcherTxt">
                                                                    <label>{
                                                                        getType(get(currentQuestion, 'type', ''))}</label>
                                                                </div>
                                                                <div className="quesTimer">
                                                                    {currentQuestion?.type !== 'informational_page' ? (
                                                                        <label className="quesAnsTimerCount">{time}</label>
                                                                    ) : <label className="quesAnsZeroTimerCount" />}
                                                                </div>
                                                            </div>
                                                            <animated.div className="openBounceIn">
                                                                <div className="questionViewMid mb-12 questionBoxSize">
                                                                    {currentQuestion?.type === 'type_answer' ? (
                                                                        <div className="plyOpnEnd text-center">
                                                                            <p className='noBreakStyle'>{get(quizQuestion, 'title', '')}</p>
                                                                            <div>
                                                                                <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                                                                    <div className="dropdown-container">
                                                                                        <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1} style={{ padding: "2px" }}>
                                                                                            Á &#8659;
                                                                                        </button>


                                                                                        {dropdownVisible0 && (
                                                                                            <div className="dropdown-content accent-select">
                                                                                                <ul className="accent-list">
                                                                                                    {SPANISH_ITEM_LOWER?.map((accent, index) => (
                                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                                                                            {accent}
                                                                                                        </li>
                                                                                                    ))}
                                                                                                </ul>

                                                                                                <ul className="accent-list">
                                                                                                    {SPANISH_ITEM_UPPER?.map((accent, index) => (
                                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                                                                            {accent}
                                                                                                        </li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <textarea ref={textareaRef} value={selectedAnswers} placeholder="Type your answer here:" onChange={(e) => {
                                                                                    handleInputChange(e)
                                                                                }} />
                                                                                <div
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        bottom: 30,
                                                                                        right: 30,
                                                                                        padding: '5px',
                                                                                        color: '#888', // Change the color as per your design
                                                                                        fontSize: '14px',
                                                                                    }}
                                                                                >
                                                                                    {selectedAnswers.length}/{currentQuestion?.type === 'type_answer' ? '80' : '130'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        currentQuestion?.type === 'brainstorm' || currentQuestion?.type === 'open_ended' || currentQuestion?.type === 'word_cloud' ? (
                                                                            <div className="plyOpnEnd">
                                                                                <div className='frmatUpr vobalCenter'>
                                                                                    <p className='frmatTextLft noBreakStyle'>{get(quizQuestion, 'title', '')}</p>
                                                                                    <div className="frmatTextRght">
                                                                                        <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1} style={{ padding: "2px" }}>
                                                                                            Á &#8659;
                                                                                        </button>
                                                                                        {dropdownVisible0 && (
                                                                                            <div className="dropdown-content accent-select">
                                                                                                <ul className="accent-list">
                                                                                                    {SPANISH_ITEM_LOWER?.map((accent, index) => (
                                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent, currentQuestion?.type)}>
                                                                                                            {accent}
                                                                                                        </li>
                                                                                                    ))}
                                                                                                </ul>

                                                                                                <ul className="accent-list">
                                                                                                    {SPANISH_ITEM_UPPER?.map((accent, index) => (
                                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent, currentQuestion?.type)}>
                                                                                                            {accent}
                                                                                                        </li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <textarea ref={textareaRef} value={selectedAnswers} placeholder="Type your answer here:" onChange={(e) => {
                                                                                        handleInputChange(e);
                                                                                    }} />

                                                                                    <div
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            bottom: 30,
                                                                                            right: 30,
                                                                                            padding: '5px',
                                                                                            color: '#888', // Change the color as per your design
                                                                                            fontSize: '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            currentQuestion.type === 'word_cloud'
                                                                                                ? `${selectedAnswers.length}/20`
                                                                                                : `${selectedAnswers.length}/130`
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="teacherQuesDetail previewTeacherDetail">
                                                                                <p className="noBreakStyle">{get(quizQuestion, 'title', '')}</p>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </animated.div>
                                                        </>

                                                    }
                                                    {/* <div className="quesDetails quesDetaisTr">
                                                        <div className="topTcherTxt">
                                                            <label>{
                                                                getType(get(currentQuestion, 'type', ''))}</label>
                                                        </div>
                                                        <div className="quesTimer">
                                                            {currentQuestion?.type !== 'informational_page' ? (
                                                                <label className="quesAnsTimerCount">{time}</label>
                                                            ) : <label className="quesAnsZeroTimerCount" />}
                                                        </div>
                                                    </div>
                                                    <animated.div className="openBounceIn">
                                                        <div className="questionViewMid mb-12 questionBoxSize">
                                                            {currentQuestion?.type === 'type_answer' ? (
                                                                <div className="plyOpnEnd text-center">
                                                                    <p className='noBreakStyle'>{get(quizQuestion, 'title', '')}</p>
                                                                    <div>
                                                                        <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <div className="dropdown-container">
                                                                                <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1} style={{ padding: "2px" }}>
                                                                                    Á &#8659;
                                                                                </button>


                                                                                {dropdownVisible0 && (
                                                                                    <div className="dropdown-content accent-select">
                                                                                        <ul className="accent-list">
                                                                                            {SPANISH_ITEM_LOWER?.map((accent, index) => (
                                                                                                <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                                                                    {accent}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>

                                                                                        <ul className="accent-list">
                                                                                            {SPANISH_ITEM_UPPER?.map((accent, index) => (
                                                                                                <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                                                                    {accent}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <textarea ref={textareaRef} value={selectedAnswers} placeholder="Type your answer here:" onChange={(e) => {
                                                                            handleInputChange(e)
                                                                        }} />
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: 30,
                                                                                right: 30,
                                                                                padding: '5px',
                                                                                color: '#888', // Change the color as per your design
                                                                                fontSize: '14px',
                                                                            }}
                                                                        >
                                                                            {selectedAnswers.length}/{currentQuestion?.type === 'type_answer' ? '80' : '130'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                currentQuestion?.type === 'brainstorm' || currentQuestion?.type === 'open_ended' || currentQuestion?.type === 'word_cloud' ? (
                                                                    <div className="plyOpnEnd">
                                                                        <div className='frmatUpr vobalCenter'>
                                                                            <p className='frmatTextLft noBreakStyle'>{get(quizQuestion, 'title', '')}</p>
                                                                            <div className="frmatTextRght">
                                                                                <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1} style={{ padding: "2px" }}>
                                                                                    Á &#8659;
                                                                                </button>
                                                                                {dropdownVisible0 && (
                                                                                    <div className="dropdown-content accent-select">
                                                                                        <ul className="accent-list">
                                                                                            {SPANISH_ITEM_LOWER?.map((accent, index) => (
                                                                                                <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent, currentQuestion?.type)}>
                                                                                                    {accent}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>

                                                                                        <ul className="accent-list">
                                                                                            {SPANISH_ITEM_UPPER?.map((accent, index) => (
                                                                                                <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent, currentQuestion?.type)}>
                                                                                                    {accent}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <textarea ref={textareaRef} value={selectedAnswers} placeholder="Type your answer here:" onChange={(e) => {
                                                                                handleInputChange(e);
                                                                            }} />

                                                                            <div
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    bottom: 30,
                                                                                    right: 30,
                                                                                    padding: '5px',
                                                                                    color: '#888', // Change the color as per your design
                                                                                    fontSize: '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    currentQuestion.type === 'word_cloud'
                                                                                        ? `${selectedAnswers.length}/20`
                                                                                        : `${selectedAnswers.length}/130`
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="teacherQuesDetail">
                                                                        <p className="noBreakStyle">{get(quizQuestion, 'title', '')}</p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </animated.div> */}
                                                    {currentQuestion?.type === 'type_answer' ? <div className='imgContainerAQ'>
                                                        {quizQuestion?.imageUrl && (
                                                            <img className='AQimage 777' src={get(quizQuestion, 'imageUrl', '')} alt="" />
                                                        )}
                                                    </div> : (
                                                        <div className="teacherQuesViewRght">
                                                            <div className='card-body p-0'>
                                                                <div className='card-p d-flex' style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                                                                    {modernScoreConfirm ? (
                                                                        <ModernScoreAsked modernResultDecide={(e) => {
                                                                            onShowResult('', e)
                                                                            setShowAnimations(false)
                                                                            addWord()
                                                                            addPin()
                                                                            addBrainStorm();
                                                                            setmodernScoreConfirm(false);
                                                                        }} />
                                                                    ) :
                                                                        <>

                                                                            {(currentQuestion.type === 'drop_pin') ? (

                                                                                <>
                                                                                    {/* Magnifying lens */}
                                                                                    <div
                                                                                        className="dropPinSection"
                                                                                        style={{
                                                                                            position: 'relative',
                                                                                            overflow: 'hidden',
                                                                                            width: '15vh', // Adjust the size as needed
                                                                                            height: '15vh',
                                                                                            border: '2px solid #333',
                                                                                            borderRadius: '50%',
                                                                                            // Adjust the distance from the main image
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={image}
                                                                                            alt="MagnifiedView"
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                left: `-${pointerPosition.x * 2}%`,
                                                                                                top: `-${pointerPosition.y * 2}%`,
                                                                                                width: '300%', // Zoom factor
                                                                                                height: '300%',
                                                                                                padding: "51%",

                                                                                            }}
                                                                                        />
                                                                                        {/* Red dot representing pointer position */}
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                left: "50%",
                                                                                                top: "48%",
                                                                                                transform: 'translate(-50%, -50%)',
                                                                                                width: '10px',
                                                                                                height: '10px',
                                                                                                borderRadius: '50%',
                                                                                                background: 'red',
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className='imgContainerAQ imgContainerAQMn'>
                                                                                        <div className='dropPinContain droppinImage' style={{ position: 'relative' }}>
                                                                                            <img
                                                                                                style={{ border: '2px solid ', borderRadius: '0px !important' }}
                                                                                                ref={imageElement}
                                                                                                src={image}
                                                                                                alt="Uploaded"
                                                                                                className='shwPointerImg no-zoom-download tabImageSIze'
                                                                                                onContextMenu={(e) => e.preventDefault()}
                                                                                            />
                                                                                            <div
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    left: `${pointerPosition.x}%`,
                                                                                                    top: `${pointerPosition.y}%`,
                                                                                                    cursor: 'grabbing',
                                                                                                    width: '30px',  // Adjust the width to match the white dot
                                                                                                    height: '30px', // Adjust the height to match the white dot
                                                                                                    // background: `url(${require('../../utils/assets/images/pinIcon2.png')}) no-repeat center bottom / contain`,  // Use the pin image as background
                                                                                                }}
                                                                                                className='pinPointerImg'
                                                                                                onMouseDown={handlePCPointerDown}
                                                                                                onTouchStart={handleMobilePointerDown}
                                                                                                onContextMenu={(e) => {
                                                                                                    e.preventDefault(); // Prevent context menu
                                                                                                    return false; // Additional prevention for older iOS versions
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    // Replace with the actual path to your pin image
                                                                                                    alt="Pin"
                                                                                                    style={{
                                                                                                        background: `url(${require('../../utils/assets/images/pinIcon2.png')}) no-repeat center bottom / contain`,
                                                                                                        width: '30px',  // Adjust the width to match the white dot
                                                                                                        height: '35px',
                                                                                                        transform: 'translate(-50%, 0)',
                                                                                                        position: 'absolute',
                                                                                                        bottom: '30px',  // Adjust this value to position the bottom of the pin image at the center of the white dot
                                                                                                        left: '5%',  // Center the pin image horizontally
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                (quizQuestion?.imageUrl) ? (
                                                                                    <div className='imgContainerAQ'>
                                                                                        <img className='AQimage' src={get(quizQuestion, 'imageUrl', '')} alt="" />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className='imgContainerAQ'></div>
                                                                                )
                                                                            )}

                                                                            {currentQuestion?.type === 'puzzle' ? <div className="quesSelectdragAndDrop pzlWrapMainBg fullBGSectionClor">
                                                                                <div className='dragDropTxts' style={{ marginTop: 16 }}>
                                                                                    {itemsPuzzleAnswers?.map((answer, index) => {
                                                                                        return (
                                                                                            // <div className='matchingDesc'>
                                                                                            //     <p>{answer}</p>
                                                                                            // </div>
                                                                                            <div className="pzlWrapMain" style={{ marginBottom: 16 }}>
                                                                                                <div className="pzlWrapLft matchingLeftSection OrganizeText" style={{ paddingTop: 10 }}>
                                                                                                    {`Item ${index + 1}`}
                                                                                                    {/* <i class="fa-solid fa-grip-lines"></i> */}
                                                                                                </div>
                                                                                                {/* <div className="pzlWrapRght">
                                                                                        {item}
                                                                                    </div> */}
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                <DragDropContext
                                                                                    onDragEnd={handleDragEnd}
                                                                                // onDragStart={handleDragStart}
                                                                                >
                                                                                    <Droppable droppableId="droppable">
                                                                                        {(provided) => (
                                                                                            <div
                                                                                                className="pzlWrapMainBg puzzleDrpDwn pzlWrapMainBgMob"
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.droppableProps}
                                                                                            >
                                                                                                {items?.map((item, index) => (
                                                                                                    <Draggable
                                                                                                        key={index}
                                                                                                        draggableId={`option-${index}`}
                                                                                                        index={index}
                                                                                                    >
                                                                                                        {(provided) => (
                                                                                                            <div className="pzlWrapMainUpr"
                                                                                                                ref={provided.innerRef}
                                                                                                                {...provided.draggableProps}
                                                                                                                {...provided.dragHandleProps}>

                                                                                                                <div className="pzlWrapMain">
                                                                                                                    <div className="pzlWrapLft matchingLeftSection adjustHeightBtn">
                                                                                                                        {item}
                                                                                                                        {/* <i class="fa-solid fa-grip-lines"></i> */}
                                                                                                                    </div>
                                                                                                                    {/* <div className="pzlWrapRght">
                                                                                        {item}
                                                                                    </div> */}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))}
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                </DragDropContext>
                                                                                {/* <div className='dragDropTxts'>
                                                                            {itemsPuzzleAnswers?.map((answer, index) => {
                                                                                return (
                                                                                    <div className='matchingDesc'>
                                                                                        <p data-tooltip-id={`my-tooltips-${index}`}>{answer}</p>

                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div> */}
                                                                            </div> : quizAnswers?.options?.map((option, index) => (
                                                                                <animated.div key={option} className='position-relative addQuestionAnswerUprView ansStruct openBounceIn'
                                                                                // style={index % 2 === 0 ? fadeInLeftAnimation : (index % 2 !== 0 ? fadeInRightAnimation : {})}
                                                                                >
                                                                                    <div className='row g-0'>
                                                                                        <animated.div className={getOptionClassName(index)}
                                                                                            // style={springProps}
                                                                                            onClick={() => {
                                                                                                handleClick(index, option)
                                                                                            }}
                                                                                        >
                                                                                            <div className='addAnswerTchrView'>
                                                                                                <animated.div className=''

                                                                                                >
                                                                                                    <div className=''>
                                                                                                        {isImageUrl(option) ? (
                                                                                                            <>
                                                                                                                <img src={option} alt={`Option ${String.fromCharCode(65 + index)}`} className='imgContainerAQ imgContainerAQSize' />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                                <label>{`(${String.fromCharCode(65 + index)}) ${option}`}</label>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    {selectedAnswersIndex === index && <CheckCircleFilled className="correctTrueAns tfAnswer" />}
                                                                                                </animated.div>
                                                                                            </div>
                                                                                        </animated.div>
                                                                                    </div>


                                                                                </animated.div>
                                                                            ))}

                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* {currentQuestion?.type === 'drop_pin' && (
                                                        <div className="drgPinMapUpr">
                                                            <div className="dragPinMap">
                                                                <div><img src={require('../../utils/assets/images/pinIcon2.png')} alt="" /></div>
                                                                <label>(Drag the pin)</label>
                                                            </div>
                                                            <div className="mvPinWrapUpr">
                                                                <div className="mvPinWrap"
                                                                // onMouseDown={handleMouseDown} 
                                                                // onMouseUp={handleMouseUp} 
                                                                // onMouseMove={handleMouseMove}
                                                                >
                                                                    <div className="mvPinPoint"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )} */}

                                                    {selectedAnswers && !(nextTypes.includes(currentQuestion?.type)) && (
                                                        <>
                                                            <div className='row g-0 correctNxtBtnUpr butonAdjust'>
                                                                <div className='col-12'>
                                                                    <div className='teacherVwNextBtn'>
                                                                        <button onClick={() => {
                                                                            if (gamePreviewPin.game_mode === "modern") {
                                                                                setmodernScoreConfirm(true);
                                                                            } else {
                                                                                onShowResult()
                                                                                setShowAnimations(false)
                                                                                addWord()
                                                                                addPin()
                                                                                addBrainStorm();
                                                                            }
                                                                        }}>{'Submit!'}</button></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (


                                                <WantingScreentLoader functionName={""} onExitGame={""} exitLoader={""} comesFrom="" nosotros={""} isClose={""} />
                                            )}

                                        </div>
                                    </div>
                                {(showStudentWait === true && gameEnd === false ? (
                                    <div className='studentEndUpr fullbgonegame'>
                                        <div className='row g-0'>
                                            <div className='col-24 col-sm-24 col-md-24 col-lg-24 stdntCardUpr stdntCardUprss readyNxtUpr'>
                                                <div className='readyNxt'>
                                                    <p>Let's get ready for the next one!</p>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
                                                        <CountdownStopwatch expiryTimestamp={timess} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='teacherVwNextBtn'><button onClick={() => {
                                        nextSection()
                                    }}>Next Question!</button></div> */}
                                    </div>
                                ) : gameEnd === true ? (
                                    <>
                                        {bestPlayerScrn ? (
                                            // <div className='studentEndUpr fullbgonegame'>
                                            <div className={gamePreviewPin.game_mode === "modern" ? 'studentEndUprModern fullbgonegame' : 'studentEndUpr fullbgonegame'}>
                                                <div className="teacherQuesView scoreAnsView">
                                                    <div className="allQuizScoreSection">
                                                        <div  >
                                                            <div className="topScoreSection topBeatingSection">
                                                                <div className="">
                                                                    <animated.p
                                                                    // style={animatedTextStyles}
                                                                    >
                                                                        Beating everyone!
                                                                    </animated.p>
                                                                </div>
                                                            </div>
                                                            <div className="allBeatingTxt">
                                                                {/* {map(playerList3.slice(0, 1), (player, index) => {
                                                                        return ( */}
                                                                <Row>
                                                                    <Col xs={{ span: 11 }} sm={{ span: 7 }} lg={{ span: 7 }} className="resultFstWiner centertext">
                                                                        <div className="resultLstImgPart">

                                                                            {/* <img src={require('../../utils/assets/images/Beating.png')} alt="" /> */}
                                                                            <animated.label>{'1'}</animated.label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={{ span: 13 }} sm={{ span: 8 }} lg={{ span: 8 }} className="resultFstWiner centertext">
                                                                        <animated.div
                                                                            className="resultLstTxtPart"
                                                                        // style={thirdResultAnimation}
                                                                        >
                                                                            <span>{gamePreviewPin.game_mode === "modern" ? 'Dummy 1' : 'Dummy Student 1'}<br className="resultLstTxt" /></span>
                                                                        </animated.div>
                                                                    </Col>
                                                                    <Col xs={{ span: 24 }} sm={{ span: 9 }} lg={{ span: 9 }} className="resultFstWiner">
                                                                        <div className="allBeatingImg">
                                                                            <img style={{ height: 250 }} alt="" src={require('../../utils/assets/images/dance_human.gif')} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* )
                                                                     })} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
                                                {randomContent}
                                            </div>
                                        )}

                                    </>
                                ) : showResult === true ? (
                                    <div className={gamePreviewPin.game_mode === "modern" ? 'studentEndUprModern fullbgonegame' : 'studentEndUpr fullbgonegame'}>


                                        <div className="prjctrWraper">
                                            <StudentEndResult
                                                currentQuestion={currentQuestion}
                                                selectedAnswersIndex={selectedAnswersIndex}
                                                selectedAnswers={selectedAnswers}
                                                isCorrect={isCorrect}
                                                quotation={quotation}
                                                nextSection={() => {
                                                    // onStudentEnd();
                                                    if (gamePreviewPin.game_mode === "modern") {
                                                        if (gamePreviewPin?.games_metadata?.gameChanceType === 'EndGame') {
                                                            nextSection();
                                                        } else {
                                                            nextSectionModern();
                                                        }
                                                    }
                                                }}
                                                quizAnswers={quizAnswers}
                                                currentScoreModern={showPlayerResult}
                                                gamePreviewPin={gamePreviewPin}
                                            />
                                        </div>
                                    </div>
                                ) : null)}
                            </div>
                        )}
                    </Col>
                </Row>
            </Drawer>
        </div>
    )
};


export default PreviewGame;

