import { CheckOutlined, LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { Button, Spin, notification } from 'antd';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../auth';
import Acent from '../components/Acent';
import { ImageModal } from '../components/ImageModal';
import { CreateGameQuestion } from '../store/GameQuestions/thunks';
import { setActivity } from '../store/TabChange/thunks';
import { isImageUrl } from '../utils/methods/QuizeMethodData';

const Index = ({ questionType, isEditable, edit, CardTime, CardOption, activity }) => {
    const { currentGameContext } = useAuth();
    const getCurrentGame = currentGameContext;
    const gametype = get(getCurrentGame, 'game_type', {}) ?? {}
    const [clonedDivs, setClonedDivs] = useState([]);
    const [addAnsShow, setaddAnsBtn] = useState(true);
    const [answerData, setAnswerData] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image1, setImage1] = useState(false)
    const [image2, setImage2] = useState(false)
    const [image3, setImage3] = useState(false)
    const [image4, setImage4] = useState(false)
    const [image5, setImage5] = useState(false)
    const [image6, setImage6] = useState(false)
    const { currentUser } = useAuth()
    const dispatch = useDispatch();
    const location = useLocation()
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    const [isChecked, setIsChecked] = useState([]);
    const [correctanswer, setCorrectanswer] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState('');
    const [showModalAns, setShowModalAns] = useState(false);
    const [uploadedImageAns, setUploadedImageAns] = useState('');
    const [typeQAImg, setTypeQAImg] = useState('');
    const [api, contextHolder] = notification.useNotification();
    // const [isCheckedBox, setIsCheckedBox] = useState(false);
    const [removeImageLoader, setRemoveImageLoader] = useState(false);
    const textareaRef = useRef(null);
    const textarea1Ref = useRef(null);
    const textarea2Ref = useRef(null);
    const textarea3Ref = useRef(null);
    const textarea4Ref = useRef(null);
    const textarea5Ref = useRef(null);
    const textarea6Ref = useRef(null);


    // useEffect(() => {
    //     if (isEditable) {
    //         const Question = get(edit, 'question', '') ?? {};
    //         const Answer = get(edit, 'answers', '') ?? {};
    //         console.log('Answer', Answer);
    //         setInputValue(Question?.title);
    //         setUploadedImage(Question?.imageUrl)
    //     }
    // }, [])

    // const springProps = useSpring({
    //     backgroundColor: isCheckedBox ? "#1e7709" : "#f0f0f0",
    //     transform: isCheckedBox ? "scale(1.1)" : "scale(1)",
    // });

    // const toggleCheckbox = () => {
    //     setIsCheckedBox(!isCheckedBox);
    // };

    // const handleLstButtonClick = () => {
    //     setIsChecked(!isChecked);
    // };

    useEffect(() => {

        if (isEditable) {
            const Question = get(edit, 'question', '') ?? {};
            const Answer = get(edit, 'answers', '') ?? {};
            // Set question title and image
            setInputValue(Question?.title);
            setUploadedImage(Question?.imageUrl);
            // Set options in state
            if (Answer.options && Array.isArray(Answer.options)) {
                const updatedAnswerData = Answer.options.reduce((acc, option, index) => {
                    acc[`answer${index + 1}`] = option;
                    return acc;
                }, {});
                setAnswerData(updatedAnswerData);
                // if (questionType !== "poll") {

                // Set state variables based on the presence of image URLs
                for (let i = 0; i < Answer.options.length; i++) {
                    const imageUrl = Answer.options[i];
                    const isImage = isImageUrl(imageUrl);

                    // Dynamically set the setImageX state variables
                    switch (i) {
                        case 0:
                            setImage1(isImage);
                            break;
                        case 1:
                            setImage2(isImage);
                            break;
                        case 2:
                            setImage3(isImage);
                            break;
                        case 3:
                            setImage4(isImage);
                            break;
                        case 4:
                            setImage5(isImage);
                            break;
                        case 5:
                            setImage6(isImage);
                            break;
                        // Add more cases if needed based on the expected length of Answer.options
                        default:
                            break;
                    }
                }

                const indexOfMatchingAnswer = Answer.options.reduce((acc, option, index) => {
                    if (Answer.answer && Answer.answer.includes(option)) {
                        acc.push(index);
                    }
                    return acc;
                }, []);

                const MatchingAnswer = indexOfMatchingAnswer.map(index => Answer.options[index]);

                if (Answer.options.length === 5 || Answer.options.length === 6) {

                    if (clonedDivs.length === 0) {
                        setClonedDivs([...clonedDivs, {}]);
                        setaddAnsBtn(false);

                    }
                }
                else {
                    handleRemove()
                    setaddAnsBtn(true)
                }

                setCorrectanswer(MatchingAnswer);
                setIsChecked(indexOfMatchingAnswer);
                // }
            }


        } else {
            if (activity === false) {
                const blankAns = {
                    answer1: '',
                    answer2: '',
                    answer3: '',
                    answer4: '',
                    answer5: '',
                    answer6: '',
                }
                setAnswerData(blankAns);
                setImage1(false)
                setImage6(false);
                setImage5(false);
                setImage4(false);
                setImage3(false);
                setImage2(false);
                setInputValue('');
                setaddAnsBtn(true);
                setClonedDivs([]);
                setUploadedImage('')
                setIsChecked([])
            }
        }
    }, [isEditable, edit, activity]);

    const openCheckNotification = (type) => {
        api[type]({
            message: 'Please select the correct option !',
            description:
                'Atleast one option to be checked true.',
            duration: 2,
        });
    };

    const openCheckBoxNotification = (type) => {
        api[type]({
            message: 'Please select only one correct option !',
            description:
                'Only one option to be checked true.',
            duration: 2,
        });
    };

    const openCheckNotification1 = (type) => {
        api[type]({
            message: 'Entry is invalid !',
            description:
                'Please fill the option correctly.',
            duration: 2,

        });
    };

    const openCheckNotification2 = (type) => {
        api[type]({
            message: 'Entry is invalid !',
            description:
                'Please fill unique value in option.',
            duration: 2,

        });
    };


    const handleFocus = () => {
        setIsFocused(true);

    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleClone = () => {
        setClonedDivs([...clonedDivs, {}]);
        setaddAnsBtn(!addAnsShow);
    };
// console.log(isChecked);
    const handleRemove = (index) => {
        const updatedDivs = [...clonedDivs];
        updatedDivs.splice(index, 1);
        setClonedDivs(updatedDivs);
        setaddAnsBtn(!addAnsShow);
        const updatedChecked = isChecked.filter((item) => item !== 4 && item !== 5);
        setIsChecked(updatedChecked);
        setAnswerData(prevState => ({
            ...prevState,
            answer5: "",
            answer6: ""
          }));
          setImage5(false);
          setImage6(false);
    };
    const [isAnsFocused, setIsAnsFocused] = useState(false);

    const handleAnswerFocus = () => {
        setIsAnsFocused(true);
    };

    const handleAnswerBlur = () => {
        setIsAnsFocused(false);
    };

    const handleCheck = (index) => {
        setIsChecked((prevChecked) => {
            const indexExists = prevChecked.includes(index);
            return indexExists
                ? prevChecked.filter((item) => item !== index)
                : [...prevChecked, index];
        });
    };

    // const handleChange = (key, value, isAccent) => {
    //     if (isAccent) {
    //         // If an accent is provided, concatenate it to the existing value
    //         setAnswerData({
    //             ...answerData,
    //             [key]: (answerData[key] || '') + value,
    //         });
    //     } else {
    //         // If no accent is provided, update the key-value pair in the state
    //         setAnswerData({
    //             ...answerData,
    //             [key]: value,
    //         });
    //     }
    // };


    const handleChange = (key, value, isAccent, textareaRef, type) => {
        if (type === "image") {
            setAnswerData({
                ...answerData,
                [key]: value,
            });
        }
        else {
            if (isAccent) {
                const textarea = textareaRef.current;
                const startPos = textarea.selectionStart;
                const endPos = textarea.selectionEnd;

                // If an accent is provided, concatenate it to the existing value
                const newValue =
                    (answerData[key] || '').substring(0, startPos) +
                    value +
                    (answerData[key] || '').substring(endPos);

                // Enforce a maximum character limit of 80 for individual content
                const trimmedValue = newValue.substring(0, 80);

                setAnswerData({
                    ...answerData,
                    [key]: trimmedValue,
                });

                // Move the cursor to the right after the added accent
                const newCursorPos = startPos + value.length;

                // Set the new cursor position immediately after the added accent
                setTimeout(() => {
                    textarea.setSelectionRange(newCursorPos, newCursorPos);
                    textarea.focus();
                }, 0);
            } else {
                const maxLength = 80;
                // Enforce a maximum character limit of 80 for individual content
                const trimmedValue = value.slice(0, maxLength);

                // If no accent is provided, update the key-value pair in the state
                setAnswerData({
                    ...answerData,
                    [key]: trimmedValue,
                });
            }

        }
    };


    const cleaning = (data) => {
        const nonEmptyIndexes = [];
        const emptyIndexes = [];

        Object.keys(data).forEach((key, index) => {
            const value = data[key];

            if (value !== "") {
                nonEmptyIndexes.push(index);
            } else {
                // If a value was found previously, ignore empty values
                emptyIndexes.push(index);
            }
        });

        if (emptyIndexes.length != 0) {
            let shouldReturnFalse = true;
            nonEmptyIndexes.forEach((nonEmptyIndex) => {
                const emptyIndex = emptyIndexes[0];
                if (emptyIndex < nonEmptyIndex) {
                    shouldReturnFalse = false;
                }
            });

            if (shouldReturnFalse === false) {
                openCheckNotification1('error')
                return { status: false, data: {} };
            }
            else {
                emptyIndexes.forEach(emptyIndex => {
                    const keyToDelete = `answer${emptyIndex + 1}`;

                    if (data.hasOwnProperty(keyToDelete)) {
                        delete data[keyToDelete];
                    } else {
                        console.warn(`Key ${keyToDelete} is undefined or does not exist.`);
                    }
                });
                // setCleanAnswerData(data); // Store cleaned data in state
                return { status: true, data: data };
            }
        } else {
            // No empty indexes, simply set the cleaned data
            // setCleanAnswerData(data);
            return { status: true, data: data };
        }
    };

    const hasDuplicate = (data) => {
        // Check if data is empty
        if (Object.keys(data).length === 0) {
            return { status: false, data: {} }; // Return false if data is empty
        }
        const uniqueValues = new Set();

        for (const [key, value] of Object.entries(data)) {
            if (value !== "") {

                if (uniqueValues.has(value)) {
                    openCheckNotification2('error');
                    return { status: false, data: {} }; // Return false if a non-unique value is found
                } else {
                    uniqueValues.add(value);
                }
            }
        }
        // If no duplicates found, return a status indicating success
        return { status: true, data: data };
    };

    const onHandleQuestion = async (type) => {

        var clean1 = await cleaning(answerData);

        var clean = await hasDuplicate(clean1.data);


        if (clean.status === true) {

            var cleananswerData = clean.data;

            const question = {
                title: inputValue,
                imageUrl: `${uploadedImage ? uploadedImage : ''}`,
                time: CardTime,
                option: CardOption
            }
            if (questionType === 'poll') {
                // let answers = {
                //     options: Object.values(answerData),
                //     answer: "",
                // }
                let updatedAnswerData;

                if (clonedDivs?.length === 0) {
                    // Ensure indices 4 and 5 are within the array bounds
                    if (Object.keys(cleananswerData).length > 5) {
                        // Create a shallow copy of cleananswerData
                        updatedAnswerData = { ...cleananswerData };

                        // Remove properties at indices 4 and 5
                        delete updatedAnswerData[Object.keys(cleananswerData)[4]];
                        delete updatedAnswerData[Object.keys(cleananswerData)[5]];
                    }
                    else {
                        updatedAnswerData = { ...cleananswerData };

                    }
                }
                else {
                    updatedAnswerData = { ...cleananswerData };
                }
                if (isEditable) {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: "",
                    }
                    const data = {
                        ...edit,
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type: questionType,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                }
                else {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: "",
                    }
                    const data = {
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type: questionType,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                    const blankAns = {
                        answer1: '',
                        answer2: '',
                        answer3: '',
                        answer4: '',
                        answer5: '',
                        answer6: '',
                    }
                    setAnswerData(blankAns);
                    setInputValue('');
                    setaddAnsBtn(true);
                    setClonedDivs([]);
                    setUploadedImage('')
                    setIsChecked([])
                }

                // setInputValue('')
            } else {
                let updatedAnswerData;

                if (clonedDivs?.length === 0) {
                    // Ensure indices 4 and 5 are within the array bounds
                    if (Object.keys(cleananswerData).length > 5) {
                        // Create a shallow copy of cleananswerData
                        updatedAnswerData = { ...cleananswerData };

                        // Remove properties at indices 4 and 5
                        delete updatedAnswerData[Object.keys(cleananswerData)[4]];
                        delete updatedAnswerData[Object.keys(cleananswerData)[5]];
                    }
                    else {
                        updatedAnswerData = { ...cleananswerData };

                    }
                }
                else {
                    updatedAnswerData = { ...cleananswerData };
                }


                const optionsArray = Object.values(updatedAnswerData);
                const Answer = isChecked.map(index => optionsArray[index]);
                if (isEditable) {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: Answer,
                    }
                    const UpdateData = {
                        ...edit,
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(UpdateData))
                    setAnswerData(updatedAnswerData)
                }
                else {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: Answer,
                    }
                    const data = {
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                    const blankAns = {
                        answer1: '',
                        answer2: '',
                        answer3: '',
                        answer4: '',
                        answer5: '',
                        answer6: '',
                    }
                    setAnswerData(blankAns);
                    setInputValue('');
                    setaddAnsBtn(true);
                    setClonedDivs([]);
                    setUploadedImage('')
                    setIsChecked([])
                }
            }
            setLoading(false);
        }
        else {
            setTimeout(() => {
                setLoading(false);
            }, 300);

        }
    }

    const uploadOptionImage = (mediaUrl) => {
        if (typeQAImg === 'answer1') {
            handleChange('answer1', mediaUrl, "", "", "image");
            setImage1(true);
        }
        if (typeQAImg === 'answer2') {
            handleChange('answer2', mediaUrl, "", "", "image");
            setImage2(true);
        }
        if (typeQAImg === 'answer3') {
            handleChange('answer3', mediaUrl, "", "", "image");
            setImage3(true);
        }
        if (typeQAImg === 'answer4') {
            handleChange('answer4', mediaUrl, "", "", "image");
            setImage4(true);
        }
        if (typeQAImg === 'answer5') {
            handleChange('answer5', mediaUrl, "", "", "image");
            setImage5(true);
        }
        if (typeQAImg === 'answer6') {
            handleChange('answer6', mediaUrl, "", "", "image");
            setImage6(true);
        }
    }

    const onRemoveImage = (type) => {
        // const imageRef = ref(storage, `${location}/${id}/${file.name}`);
        if (type === 'answer1') {
            setImage1(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });

        }
        if (type === 'answer2') {
            setImage2(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });
        }
        if (type === 'answer3') {
            setImage3(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });
        }
        if (type === 'answer4') {
            setImage4(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });
        }
        if (type === 'answer5') {
            setImage5(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });
        }
        if (type === 'answer6') {
            setImage6(false);
            setAnswerData({
                ...answerData,
                [type]: "",
            });
        }
    }

    const handleRemoveImage = (imageUrl) => {
        if (imageUrl) {
            setRemoveImageLoader(true)
            try {
                if (isEditable) {
                    const question = {
                        title: inputValue,
                        imageUrl: '',
                        time: CardTime,
                        option: CardOption

                    }
                    const UpdateData = {
                        ...edit,
                        question,
                    }
                    dispatch(CreateGameQuestion(UpdateData))
                    setRemoveImageLoader(false)
                    setUploadedImage('')
                }
                setUploadedImage('')
            } catch (error) {
                console.log('error', error);
                setRemoveImageLoader(false)
            }
            // const storageRef = ref(storage, imageUrl);
            // deleteObject(storageRef).then(() => {
            //     if (isEditable) {
            //         const question = {
            //             title: inputValue,
            //             imageUrl: '',
            //             time: CardTime,
            //             option: CardOption

            //         }
            //         const UpdateData = {
            //             ...edit,
            //             question,
            //         }
            //         dispatch(CreateGameQuestion(UpdateData))
            //         setRemoveImageLoader(false)
            //         setUploadedImage('')
            //     }
            //     setUploadedImage('')
            // }
            // ).catch((error) => {
            //     console.log('error', error);
            //     setRemoveImageLoader(false)
            // })
        }
    }

    useEffect(() => {
        if (
            (CardTime !== '20 seconds' ||
                CardOption.id !== 1 ||
                inputValue ||
                Object.values(answerData).some(Boolean)) && !isEditable
        ) {

            // Execute dispatch here
            dispatch(setActivity({ activity: true }));
        }
        else {

            dispatch(setActivity({ activity: false }));

        }
    }, [CardTime, CardOption, inputValue, answerData]);


    const onImageUpload = (image) => {
        setUploadedImage(image)
        if (isEditable) {
            const question = {
                title: inputValue,
                imageUrl: image,
                time: CardTime,
                option: CardOption

            }
            const UpdateData = {
                ...edit,
                question,
            }
            dispatch(CreateGameQuestion(UpdateData))
        }
    }



    const insertAccent = (accent) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        const newInputValue =
            inputValue.substring(0, startPos) +
            accent +
            inputValue.substring(endPos);

        setInputValue(newInputValue.substring(0, 130));
        setDropdownVisible0(false);

        // Move the cursor to the right after the added accent
        const newCursorPos = startPos + accent.length;

        // Set the new cursor position immediately after the added accent
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPos, newCursorPos);
            textarea.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        const maxLength = 130;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setInputValue(trimmedValue);
    };


    // const [selectedAccent, setSelectedAccent] = useState('');

    // const handleAccentChange = (accent) => {
    //   setSelectedAccent(accent);
    // };

    const [dropdownVisible0, setDropdownVisible0] = useState(false);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const [dropdownVisible2, setDropdownVisible2] = useState(false);
    const [dropdownVisible3, setDropdownVisible3] = useState(false);
    const [dropdownVisible4, setDropdownVisible4] = useState(false);
    const [dropdownVisible5, setDropdownVisible5] = useState(false);
    const [dropdownVisible6, setDropdownVisible6] = useState(false);



    const toggleDropdown = (position) => {
        if (position === 0) {
            setDropdownVisible0(!dropdownVisible0);

        }
        if (position === 1) {
            setDropdownVisible1(!dropdownVisible1);
        } if (position === 2) {
            setDropdownVisible2(!dropdownVisible2);
        } if (position === 3) {
            setDropdownVisible3(!dropdownVisible3);
        } if (position === 4) {
            setDropdownVisible4(!dropdownVisible4);
        } if (position === 5) {
            setDropdownVisible5(!dropdownVisible5);
        } if (position === 6) {
            setDropdownVisible6(!dropdownVisible6);
        }
    };

    const handleBlur1 = () => {
        if (dropdownVisible0 || dropdownVisible1 || dropdownVisible2 || dropdownVisible3 || dropdownVisible4 || dropdownVisible5 || dropdownVisible6) {
                setDropdownVisible0(false);
                setDropdownVisible1(false);
                setDropdownVisible2(false);
                setDropdownVisible3(false);
                setDropdownVisible4(false);
                setDropdownVisible5(false);
                setDropdownVisible6(false);
        }
        // else {
        //     setDropdownVisible0(false);
        //     setDropdownVisible1(false);
        //     setDropdownVisible2(false);
        //     setDropdownVisible3(false);
        //     setDropdownVisible4(false);
        //     setDropdownVisible5(false);
        //     setDropdownVisible6(false);
        // }


    };

    useEffect(() => {
        adjustTextareaHeightInitial();
    }, [inputValue]);


    const textareaRefs = [textarea1Ref, textarea2Ref, textarea3Ref, textarea4Ref, textarea5Ref, textarea6Ref];
    const defaultTextareaHeight = 60;

    const adjustTextareaHeight = () => {
        const maxCharCountTextarea = textareaRefs.reduce((maxTextarea, currentTextarea) => {
            if (currentTextarea && currentTextarea.current) {
                return currentTextarea.current.value.length > maxTextarea.current.value.length ? currentTextarea : maxTextarea;
            }
            return maxTextarea;
        }, { current: { value: '' } });

        const minHeight = maxCharCountTextarea.current.value.length * 1.5 + defaultTextareaHeight;

        textareaRefs.forEach((ref) => {
            if (ref && ref.current) {
                ref.current.style.height = 'auto';
                ref.current.style.height = `${minHeight}px`;
            }
        });
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [answerData?.answer1, answerData?.answer2, answerData?.answer3, answerData?.answer4, answerData?.answer5, answerData?.answer6]);


    const adjustTextareaHeightInitial = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeightInitial();
        }
    };
    const handleKeyDown1 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    };
    const handleKeyDown2 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    }; const handleKeyDown3 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    }; const handleKeyDown4 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    }; const handleKeyDown5 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    };
    const handleKeyDown6 = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    };



    return (
        <>
            <div className='layoutWraper notificationText'>
                {contextHolder}

                <div className='layoutWrapLeft'>
                    <div className='addBkgroundImg'>
                        <div className='addImgShow'>
                            <div className='uploadImgUpr'>
                                {uploadedImage ? (
                                    <div className="image-container">
                                        <img src={uploadedImage} alt="Uploaded" />
                                        <div className="edit-icon">
                                            <span onClick={() => handleRemoveImage(uploadedImage)} >{removeImageLoader ? <CircularProgress size={15} style={{ color: 'teal' }} /> : null} Remove</span>
                                        </div>
                                    </div>
                                ) : (
                                    <img src={require('../utils/assets/images/upload2.png')} alt="" />
                                )}
                            </div>
                        </div>
                        <div className='uploadImgIcn'>
                            <label className="btn btn-icon" onClick={() => {
                                setShowModal(true)
                            }}>
                                <i className="fa-solid fa-plus fs-7 uploadImgPlus"></i>
                            </label>
                        </div>
                        <div className='insertMediaDP'>
                            <label>Find and insert media</label>
                        </div>
                    </div>
                    <ImageModal isOpens={showModal} isClose={setShowModal} currentGameId={currentGameId} onSubmit={(e) => {
                        onImageUpload(e)
                    }} />
                </div>

                <div className='quizLayout' onClick={() => handleBlur1()}>
                    <div className={gametype === '1study' ? 'addQuesBoxStudy' : 'addQuesBox'}>
                        <div className='playGameMidSrch my-textarea'>
                            <Acent inputRef={textareaRef} setValue={inputValue.length < 130 ? setInputValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            {/* <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="dropdown-container">
                                    <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                        Á &#8659;
                                    </button>


                                    {dropdownVisible0 && (
                                        <div className="dropdown-content accent-select">
                                            <ul className="accent-list">
                                                {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                        {accent}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className="accent-list">
                                                {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                        {accent}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div style={{ position: 'relative', marginTop: 5 }} className='padforsmall' >
                                <textarea
                                    ref={textareaRef}
                                    style={{
                                        background: 'transparent',
                                        border: 0,
                                        fontSize: 20,
                                        color: 'white',
                                        resize: 'none',
                                        marginTop: "20",
                                        outline: 'none',
                                    }}
                                    type="text"
                                    value={inputValue}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    // onKeyPress={handleKeyPress}
                                    onKeyDown={handleKeyDown}
                                    placeholder={isFocused ? '' : 'Start typing your question...'}
                                    className="custom-textarea"
                                />
                                <div className='counter-position'
                                    style={{
                                        position: 'absolute',
                                        //   bottom: -3,
                                        // bottom: 40,

                                        right: 0,
                                        color: 'white',
                                        fontSize: 12,
                                    }}
                                >
                                    {inputValue.length}/130
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-p mt-n20 position-relative for-pad'>
                        <div className='row g-0'>
                            <div className='col bg-light-warning px-6 pt-0 pb-5 rounded-2 me-7 mb-7'>

                                {(!uploadedImageAns || !image1) && (
                                    <Acent inputRef={textarea1Ref} setValue={v => handleChange('answer1', v)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                )
                                }
                                <div className='addAnswerBox'>
                                    <div className='addAnswerBxOne'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen032.svg'
                                            className='svg-icon-3x svg-icon-warning d-block quizAnimatedPart'
                                        />
                                    </div>
                                    <div className='addAnswerBxTwo image-container'>
                                        {uploadedImageAns || image1 ? (
                                            // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                            <>
                                                <img
                                                    src={answerData?.answer1}
                                                    alt="Image Alt Text"
                                                    className="image"
                                                    style={{ height: 65 }}
                                                />
                                                <div className="remove-icon">
                                                    <span onClick={() => {
                                                        onRemoveImage("answer1");
                                                    }}>Remove</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                    <select onChange={(e) => handleChange('answer1', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>á</option>
                                                        {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                    <select onChange={(e) => handleChange('answer1', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>Á</option>
                                                        {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                </div> */}

                                                {/* <textarea
                                                    ref={textarea1Ref}

                                                    type="text"
                                                    value={answerData?.answer1}
                                                    onFocus={handleAnswerFocus}
                                                    onBlur={handleAnswerBlur}
                                                    onChange={(e) => {
                                                        handleChange('answer1', e.target.value)

                                                    }}
                                                    placeholder={isAnsFocused ? '' : 'Add answer 1'}
                                                    className='addAnswerMid text-warning'
                                                />
                                                 */}


                                                <textarea
                                                    ref={textarea1Ref}
                                                    type="text"
                                                    value={answerData?.answer1}
                                                    onFocus={() => handleAnswerFocus}
                                                    onBlur={() => handleAnswerBlur}
                                                    onChange={(e) => {
                                                        handleChange('answer1', e.target.value);
                                                    }}
                                                    onKeyDown={handleKeyDown1}
                                                    placeholder={isAnsFocused ? '' : 'Add answer 1'}
                                                    className='addAnswerMid text-warning'
                                                />

                                                <div
                                                    className="text-dark"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: -3,
                                                        right: 0,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {(answerData?.answer1 || '').length}/80
                                                </div>
                                            </>
                                        )}


                                    </div>
                                    {/* {!answerData?.answer1 ? <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> : <div class="circleTrueFals" style={{ backgroundColor: isChecked == 'true' ? '' : '' }}></div>} */}
                                    {(!answerData?.answer1 ?
                                        <div onClick={() => {
                                            setShowModalAns(true)
                                            setTypeQAImg('answer1');
                                        }} className='addAnswerBxImg'>
                                            <PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} />
                                        </div>
                                        :
                                        // (questionType !== "poll" && <div onClick={() => handleCheck(0)} style={{ borderColor: 'grey' }} class="circleTrueFals">{isChecked.includes(0) && <CheckOutlined className='checkIconsAns' />}</div>)

                                        (questionType !== "poll" && (
                                            <div onClick={() => { handleCheck(0) }} className="form-check form-check-sm form-check-custom form-check-solid createCustmCheckbox " ><input className="form-check-input checkIconsAns" type="checkbox" checked={isChecked && isChecked.includes(0)} /></div>))

                                        // <div
                                        //     className="checkbox-container fourCheck"
                                        //     onClick={() => {
                                        //         toggleCheckbox()
                                        //         handleCheck(0)
                                        //     }}
                                        // >
                                        //     <animated.div
                                        //         className="checkbox"
                                        //         style={springProps}
                                        //     >
                                        //         {isCheckedBox && (
                                        //             <animated.div className="checkmark">
                                        //                 ✔
                                        //             </animated.div>
                                        //         )}
                                        //     </animated.div>
                                        // </div>
                                    )}
                                </div>
                            </div>


                            <div className='col bg-light-primary px-6 pt-0 pb-5 rounded-2 mb-7'>
                                {(!uploadedImageAns || !image2) && (
                                    <Acent inputRef={textarea2Ref} setValue={e => handleChange('answer2', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                    // <div style={{ position: 'relative', top: 0, left: 0, float: 'right' }}>
                                    //     <div className="dropdown-container">
                                    //         <button className="dropdown-button accent-select" onClick={() => toggleDropdown(2)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                    //             Á &#8659;
                                    //         </button>


                                    //         {dropdownVisible2 && (
                                    //             <div className="dropdown-content accent-select">
                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_LOWER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer2', accent, true, textarea2Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>

                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_UPPER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer2', accent, true, textarea2Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>
                                    //             </div>
                                    //         )}
                                    //     </div>
                                    // </div>
                                )
                                }
                                <div className='addAnswerBox'>
                                    <div className='addAnswerBxOne'>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr075.svg'
                                            className='svg-icon-3x svg-icon-primary d-block quizAnimatedPart'
                                        />
                                    </div>
                                    <div className='addAnswerBxTwo image-container'>

                                        {uploadedImageAns || image2 ? (
                                            // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                            <>
                                                <img
                                                    src={answerData?.answer2}
                                                    alt="Image Alt Text"
                                                    className="image"
                                                    style={{ height: 65 }}
                                                />
                                                <div className="remove-icon">
                                                    <span onClick={() => onRemoveImage("answer2")}>Remove</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                    <select onChange={(e) => handleChange('answer2', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>á</option>
                                                        {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                    <select onChange={(e) => handleChange('answer2', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>Á</option>
                                                        {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                </div> */}
                                                <textarea
                                                    ref={textarea2Ref}

                                                    type="text"
                                                    value={answerData?.answer2}

                                                    // placeholder={'Add answer 2'}
                                                    placeholder={isAnsFocused ? '' : 'Add answer 2'}

                                                    className='addAnswerMid text-primary'
                                                    onChange={(e) => handleChange('answer2', e.target.value)}
                                                    onKeyDown={handleKeyDown2}
                                                />
                                                <div
                                                    className="text-dark"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: -3,
                                                        right: 0,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {(answerData?.answer2 || '').length}/80
                                                </div>
                                            </>
                                        )}


                                    </div>
                                    {/* <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> */}
                                    {(!answerData?.answer2 ? <div onClick={() => {
                                        setShowModalAns(true)
                                        setTypeQAImg('answer2');
                                    }} className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} /></div>
                                        :
                                        (questionType !== "poll" && (
                                            <div onClick={() => { handleCheck(1) }} className="form-check form-check-sm form-check-custom form-check-solid createCustmCheckbox" ><input className="form-check-input checkIconsAns" type="checkbox" checked={isChecked && isChecked.includes(1)} /></div>))

                                    )}
                                </div>

                            </div>
                        </div>

                        <div className='row g-0'>
                            <div className='col bg-light-danger px-6 pt-0 pb-5 rounded-2 me-7 mb-7' style={{ maxHeight: `${uploadedImageAns || image4 && 150}`}}>
                                {(!uploadedImageAns || !image3) && (
                                    <Acent inputRef={textarea3Ref} setValue={e => handleChange('answer3', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                    // <div style={{ position: 'relative', top: 0, left: 0, float: "right" }}>
                                    //     <div className="dropdown-container">
                                    //         <button className="dropdown-button accent-select" onClick={() => toggleDropdown(3)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                    //             Á &#8659;
                                    //         </button>


                                    //         {dropdownVisible3 && (
                                    //             <div className="dropdown-content accent-select">
                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_LOWER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer3', accent, true, textarea3Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>

                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_UPPER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer3', accent, true, textarea3Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>
                                    //             </div>
                                    //         )}
                                    //     </div>
                                    // </div>
                                )}
                                <div className='addAnswerBox'>
                                    <div className='addAnswerBxOne'>
                                        <KTSVG
                                            path='/media/icons/duotune/abstract/abs027.svg'
                                            className='svg-icon-3x svg-icon-danger d-block quizAnimatedPart'
                                        />
                                    </div>
                                    <div className='addAnswerBxTwo image-container'>

                                        {uploadedImageAns || image3 ? (
                                            // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                            <>
                                                <img
                                                    src={answerData?.answer3}
                                                    alt="Image Alt Text"
                                                    className="image"
                                                    style={{ height: 65 }}
                                                />
                                                <div className="remove-icon">
                                                    <span onClick={() => onRemoveImage("answer3")}>Remove</span>

                                                </div>
                                            </>
                                        ) : (<>
                                            {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                <select onChange={(e) => handleChange('answer3', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                    <option value="" disabled selected>á</option>
                                                    {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                        <option key={index} value={accent}>{accent}</option>
                                                    ))}
                                                </select>
                                                <select onChange={(e) => handleChange('answer3', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                    <option value="" disabled selected>Á</option>
                                                    {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                        <option key={index} value={accent}>{accent}</option>
                                                    ))}
                                                </select>
                                            </div> */}
                                            <textarea
                                                ref={textarea3Ref}

                                                type="text"
                                                value={answerData?.answer3}
                                                placeholder={isAnsFocused ? '' : 'Add answer 3 (optional)'}
                                                className='addAnswerMid text-danger'
                                                onChange={(e) => handleChange('answer3', e.target.value)}
                                                onKeyDown={handleKeyDown3}

                                            />
                                            <div
                                                className="text-dark"
                                                style={{
                                                    position: 'absolute',
                                                    bottom: -3,
                                                    right: 0,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {(answerData?.answer3 || '').length}/80
                                            </div>
                                        </>
                                        )}
                                    </div>
                                    {/* <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> */}
                                    {/* {!answerData?.answer3 ? <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> : <div class="circleTrueFals" style={{ backgroundColor: isChecked == 'true' ? '' : '' }}></div>} */}
                                    {(!answerData?.answer3 ? <div onClick={() => {
                                        setShowModalAns(true)
                                        setTypeQAImg('answer3');

                                    }} className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} /></div> :

                                        // (questionType !== "poll" && <div onClick={() => handleCheck(2)} style={{ borderColor: 'grey' }} class="circleTrueFals">{isChecked.includes(2) && <CheckOutlined className='checkIconsAns' />}</div>))
                                        (questionType !== "poll" && (
                                            <div onClick={() => { handleCheck(2) }} className="form-check form-check-sm form-check-custom form-check-solid createCustmCheckbox" ><input className="form-check-input checkIconsAns" type="checkbox" checked={isChecked && isChecked.includes(2)} /></div>))

                                    )}


                                </div>
                            </div>

                            <div className='col bg-light-success px-6 pt-0 pb-5 rounded-2 mb-7'>
                                {(!uploadedImageAns || !image4) && (
                                    <Acent inputRef={textarea4Ref} setValue={e => handleChange('answer4', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                    // <div style={{ position: 'relative', top: 0, left: 0, float: "right" }}>
                                    //     <div className="dropdown-container">
                                    //         <button className="dropdown-button accent-select" onClick={() => toggleDropdown(4)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                    //             Á &#8659;
                                    //         </button>


                                    //         {dropdownVisible4 && (
                                    //             <div className="dropdown-content accent-select">
                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_LOWER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer4', accent, true, textarea4Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>

                                    //                 <ul className="accent-list">
                                    //                     {SPANISH_ITEM_UPPER.map((accent, index) => (
                                    //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer4', accent, true, textarea4Ref)}>
                                    //                             {accent}
                                    //                         </li>
                                    //                     ))}
                                    //                 </ul>
                                    //             </div>
                                    //         )}
                                    //     </div>
                                    // </div>
                                )
                                }
                                <div className='addAnswerBox'>
                                    <div className='addAnswerBxOne'>
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com010.svg'
                                            className='svg-icon-3x svg-icon-success d-block quizAnimatedPart'
                                        />
                                    </div>
                                    <div className='addAnswerBxTwo image-container'>

                                        {uploadedImageAns || image4 ? (
                                            // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                            <>
                                                <img
                                                    src={answerData?.answer4}
                                                    alt="Image Alt Text"
                                                    className="image"
                                                    style={{ height: 65 }}
                                                />
                                                <div className="remove-icon">
                                                    <span onClick={() => onRemoveImage("answer4")}>Remove</span>

                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                    <select onChange={(e) => handleChange('answer4', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>á</option>
                                                        {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                    <select onChange={(e) => handleChange('answer4', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                        <option value="" disabled selected>Á</option>
                                                        {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                            <option key={index} value={accent}>{accent}</option>
                                                        ))}
                                                    </select>
                                                </div> */}
                                                <textarea
                                                    ref={textarea4Ref}

                                                    type="text"
                                                    value={answerData?.answer4}
                                                    placeholder={isAnsFocused ? '' : 'Add answer 4 (optional)'}
                                                    className='addAnswerMid text-success'
                                                    onChange={(e) => handleChange('answer4', e.target.value)}
                                                    onKeyDown={handleKeyDown4}

                                                />
                                                <div
                                                    className="text-dark"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: -3,
                                                        right: 0,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {(answerData?.answer4 || '').length}/80
                                                </div>
                                            </>
                                        )}




                                    </div>
                                    {/* <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> */}
                                    {/* {!answerData?.answer4 ? <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> : <div class="circleTrueFals" style={{ backgroundColor: isChecked == 'true' ? '' : '' }}></div>} */}
                                    {(!answerData?.answer4 ? <div onClick={() => {
                                        setShowModalAns(true)
                                        setTypeQAImg('answer4');

                                    }} className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} /></div> :

                                        // (questionType !== "poll" && <div onClick={() => handleCheck(3)} style={{ borderColor: 'grey' }} class="circleTrueFals">{isChecked.includes(3) && <CheckOutlined className='checkIconsAns' />}</div>))

                                        (questionType !== "poll" && (
                                            <div onClick={() => { handleCheck(3) }} className="form-check form-check-sm form-check-custom form-check-solid createCustmCheckbox" ><input className="form-check-input checkIconsAns" type="checkbox" checked={isChecked && isChecked.includes(3)} /></div>))

                                    )}

                                </div>
                            </div>

                        </div>

                        {clonedDivs.map((div, index) => (
                            <div className='row g-0'>
                                <div className='col bg-light-blue2 px-6 pt-0 pb-5 rounded-2 me-7 mb-7' >
                                    {(!uploadedImageAns || !image5) && (
                                        <Acent inputRef={textarea5Ref} setValue={e => handleChange('answer5', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                        // <div style={{ position: 'relative', top: 0, left: 0, float: "right" }}>
                                        //     <div className="dropdown-container">
                                        //         <button className="dropdown-button accent-select" onClick={() => toggleDropdown(5)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                        //             Á &#8659;
                                        //         </button>


                                        //         {dropdownVisible5 && (
                                        //             <div className="dropdown-content accent-select">
                                        //                 <ul className="accent-list">
                                        //                     {SPANISH_ITEM_LOWER.map((accent, index) => (
                                        //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer5', accent, true, textarea5Ref)}>
                                        //                             {accent}
                                        //                         </li>
                                        //                     ))}
                                        //                 </ul>

                                        //                 <ul className="accent-list">
                                        //                     {SPANISH_ITEM_UPPER.map((accent, index) => (
                                        //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer5', accent, true, textarea5Ref)}>
                                        //                             {accent}
                                        //                         </li>
                                        //                     ))}
                                        //                 </ul>
                                        //             </div>
                                        //         )}
                                        //     </div>
                                        // </div>
                                    )}
                                    <div className='addAnswerBox'>
                                        <div className='addAnswerBxOne'>
                                            <KTSVG
                                                path='/media/icons/duotune/abstract/abs027.svg'
                                                className='svg-icon-3x svg-icon-blue2 d-block quizAnimatedPart'
                                            />
                                        </div>
                                        <div className='addAnswerBxTwo image-container'>
                                            {uploadedImageAns || image5 ? (
                                                // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                                <>
                                                    <img
                                                        src={answerData?.answer5}
                                                        alt="Image Alt Text"
                                                        className="image"
                                                        style={{ height: 65 }}
                                                    />
                                                    <div className="remove-icon">
                                                        <span onClick={() => onRemoveImage("answer5")}>Remove</span>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                        <select onChange={(e) => handleChange('answer5', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                            <option value="" disabled selected>á</option>
                                                            {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                                <option key={index} value={accent}>{accent}</option>
                                                            ))}
                                                        </select>
                                                        <select onChange={(e) => handleChange('answer5', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                            <option value="" disabled selected>Á</option>
                                                            {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                                <option key={index} value={accent}>{accent}</option>
                                                            ))}
                                                        </select>
                                                    </div> */}
                                                    <textarea
                                                        ref={textarea5Ref}

                                                        type="text"
                                                        value={answerData?.answer5}
                                                        placeholder={isAnsFocused ? '' : 'Add answer 5 (optional)'}
                                                        className='addAnswerMid text-blue2'
                                                        onChange={(e) => handleChange('answer5', e.target.value)}
                                                        onKeyDown={handleKeyDown5}

                                                    />
                                                    <div
                                                        className="text-dark"
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: -3,
                                                            right: 0,

                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {(answerData?.answer5 || '').length}/80
                                                    </div>
                                                </>
                                            )}



                                        </div>
                                        {/* <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> */}
                                        {/* {!answerData?.answer5 ? <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> : <div class="circleTrueFals" style={{ backgroundColor: isChecked == 'true' ? '' : '' }}></div>} */}
                                        {(!answerData?.answer5 ? <div onClick={() => {
                                            setShowModalAns(true)
                                            setTypeQAImg('answer5');

                                        }} className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} /></div> : (questionType !== "poll" && <div onClick={() => handleCheck(4)} style={{ borderColor: 'grey' }} class="circleTrueFals">{isChecked.includes(4) && <CheckOutlined className='checkIconsAns' />}</div>))}
                                    </div>
                                </div>

                                <div className='col bg-light-Pink2 px-6 pt-0 pb-5 rounded-2 mb-7'>
                                    {(!uploadedImageAns || !image6) && (
                                        <Acent inputRef={textarea6Ref} setValue={e => handleChange('answer6', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                        // <div style={{ position: 'relative', top: 0, left: 0, float: "right" }}>
                                        //     <div className="dropdown-container">
                                        //         <button className="dropdown-button accent-select" onClick={() => toggleDropdown(6)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                        //             Á &#8659;
                                        //         </button>


                                        //         {dropdownVisible6 && (
                                        //             <div className="dropdown-content accent-select">
                                        //                 <ul className="accent-list">
                                        //                     {SPANISH_ITEM_LOWER.map((accent, index) => (
                                        //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer6', accent, true, textarea6Ref)}>
                                        //                             {accent}
                                        //                         </li>
                                        //                     ))}
                                        //                 </ul>

                                        //                 <ul className="accent-list">
                                        //                     {SPANISH_ITEM_UPPER.map((accent, index) => (
                                        //                         <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer6', accent, true, textarea6Ref)}>
                                        //                             {accent}
                                        //                         </li>
                                        //                     ))}
                                        //                 </ul>
                                        //             </div>
                                        //         )}
                                        //     </div>
                                        // </div>
                                    )}
                                    <div className='addAnswerBox'>
                                        <div className='addAnswerBxOne'>
                                            <KTSVG
                                                path='/media/icons/duotune/communication/com010.svg'
                                                className='svg-icon-3x svg-icon-Pink2 d-block quizAnimatedPart'
                                            />
                                        </div>
                                        <div className='addAnswerBxTwo image-container'>

                                            {uploadedImageAns || image6 ? (
                                                // <img src={uploadedImageAns} alt="Uploaded" className="upLodedImg" />
                                                <>
                                                    <img
                                                        src={answerData?.answer6}
                                                        alt="Image Alt Text"
                                                        className="image"
                                                        style={{ height: 65 }}
                                                    />
                                                    <div className="remove-icon">
                                                        <span onClick={() => onRemoveImage("answer6")}>Remove</span>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {/* <div style={{ position: 'relative', top: 0, left: 0 }}>
                                                        <select onChange={(e) => handleChange('answer6', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                            <option value="" disabled selected>á</option>
                                                            {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                                <option key={index} value={accent}>{accent}</option>
                                                            ))}
                                                        </select>
                                                        <select onChange={(e) => handleChange('answer6', e.target.value)} style={{ fontSize: '15px',backgroundColor: 'var(--bs-primary)', color: 'rgb(255, 255, 255)',border: 'none',borderRadius: '10%' }}>
                                                            <option value="" disabled selected>Á</option>
                                                            {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                                <option key={index} value={accent}>{accent}</option>
                                                            ))}
                                                        </select>
                                                    </div> */}
                                                    <textarea
                                                        ref={textarea6Ref}

                                                        type="text"
                                                        value={answerData?.answer6}
                                                        placeholder={isAnsFocused ? '' : 'Add answer 6 (optional)'}
                                                        className='addAnswerMid text-Pink2'
                                                        onChange={(e) => handleChange('answer6', e.target.value)}
                                                        onKeyDown={handleKeyDown6}

                                                    />
                                                    {/* {console.log(answerData)} */}
                                                    <div
                                                        className="text-dark"
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: -3,
                                                            right: 0,

                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {(answerData?.answer6 || '').length}/80
                                                    </div>
                                                </>
                                            )}


                                        </div>
                                        {/* <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> */}
                                        {/* {!answerData?.answer6 ? <div className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer' }} /></div> : <div class="circleTrueFals" style={{ backgroundColor: isChecked == 'true' ? '' : '' }}></div>} */}
                                        {(!answerData?.answer6 ? <div onClick={() => {
                                            setShowModalAns(true)
                                            setTypeQAImg('answer6');

                                        }} className='addAnswerBxImg'><PictureOutlined style={{ color: 'grey', cursor: 'pointer', fontSize: 22 }} /></div> : (questionType !== "poll" && <div onClick={() => handleCheck(5)} style={{ borderColor: 'grey' }} class="circleTrueFals">{isChecked.includes(5) && <CheckOutlined className='checkIconsAns' />}</div>))}
                                    </div>
                                </div>

                                <div className="moreAnsBtn"><button onClick={() => handleRemove(index)}> <AiOutlineMinusSquare />Remove additional answers</button></div>
                            </div>
                        ))}
                        {addAnsShow &&
                            <div className="moreAnsBtn"><button onClick={handleClone}> <AiOutlinePlusSquare />Add more answers</button></div>
                        }

                        <div className="moreAnsBtnSave"><Button disabled={!(inputValue.trim() !== '' && answerData.answer1.trim() && answerData.answer2.trim())} onClick={() => {
                            if (isChecked.length === 0 && questionType !== "poll") {
                                openCheckNotification('error')
                            } else if (isChecked.length > 1 && questionType !== "poll") {
                                openCheckBoxNotification('error')
                            } else {
                                onHandleQuestion("quiz");
                                setLoading(true);
                            }


                        }}>
                            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : <>  <AiOutlinePlusSquare />{`${isEditable ? 'Update' : 'save'}`}</>}</Button></div>

                        {/* <div className="moreAnsBtn"><button
                        onClick={() => setModalLeave(true)

                        }> <AiOutlinePlusSquare />Modal open</button></div> */}
                    </div>

                </div>
            </div>
            <ImageModal isOpens={showModalAns} isClose={setShowModalAns} currentGameId={currentGameId} onSubmit={(e) => {
                // setUploadedImage(e)
                uploadOptionImage(e)
            }} />
        </>
    )
}

Index.propTypes = {}

export default Index;

