/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useLayout } from '../../../../_metronic/layout/core'
import { ThemeModeSwitcher } from '../../../../_metronic/partials'
import { useAuth } from '../core/Auth'
import { login } from '../core/_requests'

// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

// const initialValues = {
//   email: '',
//   password: '',
// }
interface FormValues {
  email: string

  password: string
}
// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, saveSuperAuth } = useAuth()
  const [message, setMessage] = useState<any>('')
  const [passwordType, setPasswordType] = useState('password')
  const [resValue, setResValue] = useState<any>({
    email: '',
    password: '',
  })
  const [passwordInput, setPasswordInput] = useState(false)
  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    message && setMessage('')

    setResValue({ ...resValue, [name]: value })
  }
  const moveFP = () => {
    navigate('/auth/forgot-password', { state: resValue?.email })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const newErrors: Partial<FormValues> = {}
    message && setMessage('')
    if (!resValue.email) {
      newErrors.email = 'Please enter your registered email or phone number to proceed.'
    } else if (!resValue.password && passwordInput) {
      newErrors.password = 'Password is required to proceed. Please enter your password to continue.'
    }
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      setLoading(true)
      try {
        const { data: auth } = await login(resValue.email, resValue.password)
        setLoading(false)
        if (auth.code === 200) {
          if (auth.message === 'Enter the password') {
            setPasswordInput(true)
          } else if (auth.message === 'Please set the password') {
            navigate('password-reset', {
              state: {
                userid: auth?.user_id,
              },
            })
          } else if (auth.message === 'Please Enter otp') {
            navigate('/auth/enter-otp', {
              state: {
                token: auth?.token,
                header: 'Success',
                body: `Please enter the 6-digit OTP sent to your phone number to verify. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". For assistance, contact our support team. Thank you for verifying your phone number`,
                pos: true,
              },
            })
          }
          if (auth?.data?.is_superadmin) {
            auth['login'] = 'supeadmin'
            auth['window'] = 2
            saveSuperAuth(auth)
            auth.loggedinCompany = false;
            setCurrentUser(auth.data)
          }
          else {
            auth['login'] = 'direct'
            auth['window'] = 1
            saveAuth(auth)
            setCurrentUser(auth.data)
          }

        } else if (auth.code === 202) {
          navigate('/auth/password-reset', { state: { userid: auth?.user } })
        } else if (auth.code === 201) {
          navigate('/auth/enter-otp', {
            state: {
              header: 'Success',
              body: `Please enter the 6-digit OTP sent to your phone number to verify. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". For assistance, contact our support team. Thank you for verifying your phone number`,
              pos: true,
            },
          })
        } else {
          setMessage(auth.message)
        }
      } catch (error) {
        console.error(error)
        setMessage('Network Error')
        saveAuth(undefined)
        // setStatus('The login detail is incorrect')
        //setSubmitting(false)
        setLoading(false)
      }
    }
  }
  const togglePassword = (e: any) => {
    e.preventDefault()
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }
  const { config, classes, attributes } = useLayout()
  const { header } = config
  const [offset, setOffset] = useState<string>(`{default: '200px', lg: '300px'}`)
  useEffect(() => {
    let newString = `{default: '200px', lg: '300px'}`
    if (header.fixed.desktop) {
      if (!header.fixed.tabletAndMobile) {
        newString = `{lg: '300px'}`
      }
    } else {
      newString = `{default: '200px', lg: false}`
    }

    setOffset(newString)
  }, [header.fixed])
  return (<>
    {/* {loader && <Loading isOpen={loader} isClose={setLoader} />} */}
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>


      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        <div
          id='kt_header'
          className={`loginfluid ${clsx('', classes.header.join(' '))}`}
          {...attributes.headerMenu}
          data-kt-sticky='true'
          data-kt-sticky-name='header'
          data-kt-sticky-offset={offset}
        >
          {/* begin::Container */}
          <div
            className={clsx(
              classes.headerContainer.join(' '),
              'd-flex align-items-center justify-content-between'
            )}
            id='kt_header_container'
          >

            {/* begin::Wrapper */}

            {/* end::Wrapper */}
            <div className='d-none flex-shrink-0'>


              <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />

              {/* end::Theme mode */}


            </div>
          </div>
          {/* end::Container */}
        </div>
        <div className='w-lg-500px p-10'>
          <form
            className='form w-100 w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
            //onSubmit={handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>

              <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
            </div>

            <div className='row g-3 mb-9'>
              <div className='col-md-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  Sign in with Google
                </a>
              </div>

              <div className='col-md-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black.svg')}
                    className='theme-light-show h-15px me-3'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black-dark.svg')}
                    className='theme-dark-show h-15px me-3'
                  />
                  Sign in with Apple
                </a>
              </div>
            </div>

            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
            </div>

            <div className='fv-row mb-8'>
              <input
                placeholder='Enter Email Or Phone Number'
                type='email'
                name='email'
                autoComplete='off'
                onChange={handleInput}
                className='form-control bg-transparent'
                disabled={passwordInput}
              />
              {errors.email && <span className='loginfixderror'>{errors.email}</span>}
            </div>

            {passwordInput && (
              <div className='fv-row mb-3 ' id='setpass'>
                <input
                  type={passwordType}
                  autoComplete='off'
                  name='password'
                  className='form-control bg-transparent'
                  placeholder='Password'
                  onChange={handleInput}
                />
                <div id='passbutton'>
                  <button className='btn  ' onClick={togglePassword}>
                    {passwordType === 'password' ? (
                      <i className='bi bi-eye-slash  '></i>
                    ) : (
                      <i className='bi bi-eye '></i>
                    )}
                  </button>
                </div>

                {errors.password && <span className='loginfixderror'>{errors.password}</span>}
              </div>
            )}

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div className='form-group form-check text-left fs-8'>
              </div>
              <a
                onClick={() => moveFP()}
                className='link-primary fs-6 clickable forpasswordfix'
              >
                Forgot Password?
              </a>
            </div>

            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                onClick={(e) => handleSubmit(e)}
                disabled={loading}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress fixloadersite' >
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              {message && (
                <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                  {message}
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='alert'
                    aria-label='Close'
                  ></button>
                </div>
              )}

              {/* <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">

                <span className="indicator-label">Sign In</span>

                <span className="indicator-progress">Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>

              </button> */}
            </div>

            <div className='text-gray-500 text-center fw-semibold fs-6'>
              Not a Member yet?
              {/* <a href="../../demo1/dist/authentication/layouts/corporate/sign-up.html" className="link-primary">Sign up</a></div> */}
              <Link to='/auth/registration' className='pl-10 link-primary forpasswordfix'>
                Create an Account
              </Link>
            </div>
          </form>
        </div>
      </div>

      <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
        {/* <div className='me-10'>
          <button
            className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-offset='0px, 0px'
          >
            <img
              data-kt-element='current-lang-flag'
              className='w-20px h-20px rounded me-3'
              src={toAbsoluteUrl('/media/auth/united-states.svg')}
              alt=''
            />
            <span data-kt-element='current-lang-name' className='me-1'>
              English
            </span>
            <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
          </button>

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
            data-kt-menu='true'
            id='kt_auth_lang_menu'
          >
            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/united-states.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>English</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/spain.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Spanish</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/germany.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>German</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/japan.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Japanese</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/france.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>French</span>
              </a>
            </div>
          </div>
        </div> */}

        <div className='d-flex fw-semibold text-primary fs-base gap-5'>
          <a href='../../demo1/dist/pages/team.html' target='_blank'>
            Terms
          </a>
          <a href='../../demo1/dist/pages/pricing/column.html' target='_blank'>
            Plans
          </a>
          <a href='../../demo1/dist/pages/contact.html' target='_blank'>
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </>)
}
