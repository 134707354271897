import { LoadingOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { Button, Col, Row, Spin } from 'antd';
import { get, map } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth';
import Acent from '../components/Acent';
import { ImageModal } from '../components/ImageModal';
import { CreateGameQuestion } from '../store/GameQuestions/thunks';
import { setActivity } from '../store/TabChange/thunks';

const Index = ({ isEditable, edit, CardTime, CardOption, activity }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentUser } = useAuth()
    const maxBoxes = 4;
    const currentURL = location.pathname;
    const [boxes, setBoxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isAnsType, setIsAnsType] = useState(false);
    const currentGameId = currentURL.split('/').pop();
    const [showModal, setShowModal] = useState(false);
    const [ansTypeValue, setansTypeValue] = useState('');
    const [uploadedImage, setUploadedImage] = useState('');
    const [removeImageLoader, setRemoveImageLoader] = useState(false);
    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);
    const textareaRef3 = useRef(null);
    const textareaRef4 = useRef(null);

    const ansTypeFocus = () => {
        setIsAnsType(true);
    };
    const ansTypeBlur = () => {
        setIsAnsType(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    useEffect(() => {
        if (isEditable) {

            const Question = get(edit, 'question', '') ?? {};
            const Answer = get(edit, 'answers', '') ?? {};

            // Set question title and image
            setInputValue(Question?.title);
            setUploadedImage(Question?.imageUrl);
            // Set options in state
            // setansTypeValue(Answer.answer)
            if (Answer?.answer && Answer?.answer?.length > 0) {
                setansTypeValue(Answer?.answer[0] || '');
                const restOfAnswers = Answer?.answer?.slice(1)?.map(value => ({ answer: value || '' }));
                setBoxes(restOfAnswers);
            }

        }
        else {
            if (activity === false) {

                setansTypeValue("");
                setUploadedImage('')
                setInputValue("");
                setBoxes([])
            }

        }
    }, [isEditable, edit, activity]);


    const onHandleQuestion = async (type) => {
        const answerOptions = boxes?.map((box) => box.answer).filter((option) => option && option.trim() !== '');
        const question = {
            title: inputValue,
            imageUrl: `${uploadedImage ? uploadedImage : ''}`,
            time: CardTime,
            option: CardOption


        }
        let answers = {
            options: [ansTypeValue, ...answerOptions],
            answer: [ansTypeValue, ...answerOptions],
        }
        if (isEditable) {

            const UpdateData = {
                ...edit,
                org_id: get(currentUser, 'company_id', ''),
                created_by: get(currentUser, 'user_id', ''),
                type,
                question,
                answers,
                game_id: currentGameId
            }
            await dispatch(CreateGameQuestion(UpdateData))
        } else {
            const data = {
                org_id: get(currentUser, 'company_id', ''),
                created_by: get(currentUser, 'user_id', ''),
                type,
                question,
                answers,
                game_id: currentGameId
            }

            await dispatch(CreateGameQuestion(data));
            setInputValue('');
            setansTypeValue('');
            setBoxes([]);
        }
        setLoading(false);
        // // setIsChecked('')
    }

    const addBox = () => {
        if (boxes.length < maxBoxes) {
            setBoxes([...boxes, {}]);
        }
    };

    // const handleBoxChange = (index, value, isAccent) => {
    //     const updatedBoxes = [...boxes];

    //     if (isAccent) {
    //         // If it's an accent option, concatenate it to the existing answer
    //         const existingAnswer = updatedBoxes[index]?.answer || '';
    //         updatedBoxes[index] = { answer: existingAnswer + value };
    //     } else {
    //         // If it's the textarea, update the answer
    //         updatedBoxes[index] = { answer: value };
    //     }

    //     // Update the state
    //     setBoxes(updatedBoxes);
    // };

    const handleBoxChange = (index, value, isAccent) => {

        const updatedBoxes = [...boxes];
        const textareaRef = getTextareaRef(index);

        if (isAccent) {
            const textarea = textareaRef.current;

            if (textarea) {
                const startPos = textarea.selectionStart;
                const endPos = textarea.selectionEnd;

                const existingAnswer = updatedBoxes[index]?.answer || '';

                const newInputValue =
                    existingAnswer.substring(0, startPos) +
                    value +
                    existingAnswer.substring(endPos);

                updatedBoxes[index] = { answer: newInputValue.substring(0, 80) };

                const newCursorPos = startPos + value.length;

                setTimeout(() => {
                    textarea.setSelectionRange(newCursorPos, newCursorPos);
                    textarea.focus();
                }, 0);
            }
        } else {
            updatedBoxes[index] = { answer: value.substring(0, 80) };
        }

        setBoxes(updatedBoxes);
    };

    const getTextareaRef = (index) => {
        switch (index) {
            case 0:
                return textareaRef1;
            case 1:
                return textareaRef2;
            case 2:
                return textareaRef3;
            case 3:
                return textareaRef4;
            default:
                return null;
        }
    };

    const handleRemoveImage = (imageUrl) => {
        if (imageUrl) {
            setRemoveImageLoader(true)
            // const storageRef = ref(storage, imageUrl);
            // deleteObject(storageRef).then(() =>
            try {
                if (isEditable) {
                    const question = {
                        title: inputValue,
                        imageUrl: '',
                        time: CardTime,
                        option: CardOption

                    }
                    const UpdateData = {
                        ...edit,
                        question,
                    }
                    dispatch(CreateGameQuestion(UpdateData))
                    setRemoveImageLoader(false)
                    setUploadedImage('')
                }
                setUploadedImage('')
            } catch (error) {
                console.log('error', error);
                setRemoveImageLoader(false)
            }
        }
    }

    useEffect(() => {
        if (
            (CardTime !== '20 seconds' ||
                get(CardOption, 'id', '') !== 1 ||
                inputValue ||
                ansTypeValue) && !isEditable
        ) {
            // Execute dispatch here
            dispatch(setActivity({ activity: true }));
        }
        else {
            dispatch(setActivity({ activity: false }));

        }
    }, [CardTime, CardOption, inputValue, ansTypeValue]);

    const onImageUpload = (image) => {
        setUploadedImage(image)
        if (isEditable) {
            const question = {
                title: inputValue,
                imageUrl: image,
                time: CardTime,
                option: CardOption
            }
            const UpdateData = {
                ...edit,
                question,
            }
            dispatch(CreateGameQuestion(UpdateData))
        }
    }

    const textareaRef = useRef(null);
    const textareaRef11 = useRef(null);

    const handleInputChange = (e) => {
        // const value = e.target.value;
        // if (value.length <= 130) {
        //     setInputValue(value);
        //     adjustTextareaHeight();
        // }
        const maxLength = 130;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setInputValue(trimmedValue);
        adjustTextareaHeight();
    };

    const handleInputChange2 = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 80) {
            setansTypeValue(inputValue);
        } else {
            // Truncate the input to 80 characters
            setansTypeValue(inputValue.slice(0, 80));
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [inputValue]);

    useEffect(() => {
        adjustTextareaHeight2();
    }, [ansTypeValue]);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            adjustTextareaHeight();
        }
    };

    const adjustTextareaHeight2 = () => {
        if (textareaRef11.current) {
            textareaRef11.current.style.height = 'auto';
            textareaRef11.current.style.height = `${textareaRef11.current.scrollHeight}px`;
        }
    };

    const handleKeyDown2 = (e) => {
        if (e.key === 'Enter') {
            adjustTextareaHeight2();
        }
    };

    const adjustTextareaHeight3 = (index) => {
        if (getTextareaRef(index).current) {
            getTextareaRef(index).current.style.height = 'auto';
            getTextareaRef(index).current.style.height = `${getTextareaRef(index).current.scrollHeight}px`;
        }
    };

    const handleKeyDown3 = (e, index) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight3(index);
        }
    };

    useEffect(() => {
        adjustTextareaHeight3(0);
    }, [boxes[0]?.answer]);

    useEffect(() => {
        adjustTextareaHeight3(1);
    }, [boxes[1]?.answer]);

    useEffect(() => {
        adjustTextareaHeight3(2);
    }, [boxes[2]?.answer]);

    useEffect(() => {
        adjustTextareaHeight3(3);
    }, [boxes[3]?.answer]);

    return (
        <div className='layoutWraper'>

            <div className='layoutWrapLeft'>
                <div className='addBkgroundImg'>
                    <div className='addImgShow'>
                        <div className='uploadImgUpr'>
                            {uploadedImage ? (
                                <div className="image-container">
                                    <img src={uploadedImage} alt="Uploaded" />
                                    <div className="edit-icon">
                                        <span onClick={() => handleRemoveImage(uploadedImage)} >{removeImageLoader ? <CircularProgress size={15} style={{ color: 'teal' }} /> : null} Remove</span>
                                    </div>
                                </div>
                            ) : (
                                <img src={require('../utils/assets/images/upload2.png')} alt="" />
                            )}
                        </div>
                    </div>
                    <div className='uploadImgIcn'>
                        <label className="btn btn-icon" onClick={() => {
                            setShowModal(true)
                        }}>
                            <i className="fa-solid fa-plus fs-7 uploadImgPlus"></i>
                        </label>
                    </div>
                    <div className='insertMediaDP'>
                        <label>Find and insert media</label>
                    </div>
                </div>
                <ImageModal isOpens={showModal} isClose={setShowModal} currentGameId={currentGameId} onSubmit={(e) => {
                    onImageUpload(e)
                }} />
            </div>

            <div className='quizLayout'>
                <div className='card-body p-0'>
                    <div className='addQuesBox'>
                        <div className='playGameMidSrch my-textarea'>
                            <Acent inputRef={textareaRef} setValue={inputValue?.length < 130 ? setInputValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            <div style={{ position: 'relative', marginTop: 5 }}>
                                <textarea
                                    ref={textareaRef}
                                    style={{
                                        background: 'transparent',
                                        border: 0,
                                        fontSize: 20,
                                        color: 'white',
                                        resize: 'none',
                                        // height: '40px', // Set the height for 2 rows of text
                                        overflowY: 'hidden', // Hide extra content beyond the defined height
                                        marginTop: "20",
                                        outline: 'none',
                                        // height:"50"

                                    }}
                                    type="text"
                                    value={inputValue}
                                    onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    onBlur={() => handleBlur()}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}

                                    placeholder={isFocused ? '' : 'Start typing your question...'}
                                    className="custom-textarea"
                                />
                                <div className='counter-position-typeanswer'
                                    style={{
                                        position: 'absolute',
                                        // bottom: -3,
                                        right: 0,
                                        color: 'white',
                                        fontSize: 12,
                                    }}
                                >
                                    {inputValue.length}/130
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-p mt-n20 position-relative'>
                    <div className='row g-0'>
                        <div className='col px-6 rounded-2 mb-5 typeAnswMidBg'>
                            <Acent inputRef={textareaRef11} setValue={ansTypeValue?.length < 80 ? setansTypeValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            <div className='typeAnswMid'>
                                <textarea
                                    type="text"
                                    ref={textareaRef11}
                                    onFocus={ansTypeFocus}
                                    // onBlur={ansTypeBlur}
                                    onBlur={() => ansTypeBlur()}
                                    value={ansTypeValue}
                                    onChange={handleInputChange2}
                                    onKeyDown={handleKeyDown2}
                                    placeholder={isAnsType ? '' : 'Type an answer'}
                                    className='addAnswerMid'
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        // bottom: -3,
                                        bottom: -11.5,
                                        right: 0,
                                        color: 'black',
                                        fontSize: 12,
                                    }}
                                >
                                    {(ansTypeValue || '').length}/80 sdfsdfsd
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row gutter={20}>
                        {map(boxes, (box, index) => {
                            return (
                                <Col xs={24} lg={12} style={{ marginTop: 15 }}>
                                    <div style={{ position: 'relative', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, top: -1 }} className="typeAnswMid typeAnswMidBg smallboxpadding typeAnswAddOns">
                                        <Acent inputRef={getTextareaRef(index)} setValue={e => handleBoxChange(index, e, false)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                        
                                        <div>
                                            <textarea
                                                ref={getTextareaRef(index)}
                                                type="text"
                                                placeholder="Type an answer"
                                                className='addAnswerMid'
                                                value={box?.answer || ''}
                                                onChange={(e) => handleBoxChange(index, e.target.value, false)}
                                                onKeyDown={(e) => handleKeyDown3(e, index)}

                                            />
                                            <div style={{
                                                position: 'absolute',
                                                // bottom: -3,
                                                bottom: 1.5,
                                                right: 10,
                                                color: 'black',
                                                fontSize: 12,
                                            }} >
                                                {(box?.answer || '').length}/80
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            )
                        })}
                    </Row>
                </div>

                <div className="moreAnsBtn addingMoreBtnSec"><button style={{ opacity: boxes?.length === maxBoxes ? 0.5 : 1 }} disabled={boxes?.length === maxBoxes} onClick={addBox}> <AiOutlinePlusSquare />{`Add Other Accepted Answer (${maxBoxes - boxes?.length})`}</button></div>

                {/* {inputValue !== '' && ansTypeValue && ( */}
                <div className="moreAnsBtnSaveQuestions"><Button disabled={inputValue.trim() === '' || !ansTypeValue.trim()} onClick={() => {
                    onHandleQuestion('type_answer')
                    setLoading(true);
                }}>{loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : <>  <AiOutlinePlusSquare />{`${isEditable ? 'Update' : 'save'}`}</>}</Button></div>
                {/* )} */}
            </div>
        </div>
    )
}

Index.propTypes = {}

export default Index;
