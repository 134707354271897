import { CloseCircleFilled, PictureOutlined } from '@ant-design/icons';
import { getAppBackendUrl } from '@app/modules/apps/IPlayGame/utils/methods';
import { Drawer, notification } from 'antd';
import axios from 'axios';
import { deleteObject, ref } from "firebase/storage";
import { cloneDeep, findIndex, get, map } from 'lodash';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BiBorderAll } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePageData } from '../../../../../../_metronic/layout/core';
import { useAuth } from '../../../../auth';
import { storage } from '../../firebaseConfig';
import { useGameQuestions } from '../../store/GameQuestions/selectors';
import { CreateGameQuestion, UpdateQuestionOrder, deleteGameQuestion, getGameQuestion } from '../../store/GameQuestions/thunks';
import { setActionTab, setFeatures } from '../../store/TabChange/thunks';
import { DeleteModal } from '../DeleteModal';

var bufferMessages = []

const API_URL = getAppBackendUrl()

const TimeDisplay = ({ item }) => {
 const isNotInfoPage = get(item, 'type') !== "informational_page";
    const questionTime = get(item, 'question.time', '');

    const timeMap = {
        '20 seconds': '20',
        '30 seconds': '30',
        '1 minute': '60',
        '1 minute 30 seconds': '90',
        '2 minutes': '120',
        '3 minutes': '180',
        '4 minutes': '240',
    };

    return (
        <>
            {isNotInfoPage && <div className='time'>{timeMap[questionTime] || ''}</div>}
        </>
    );
};


const QuestionDrawer = ({ isOpen, open, onClose = () => null, title, mobOpen }) => {
    const [chatUpdateFlag, toggleChatUpdateFlat] = useState(false)
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({
        user_id: '',
        avatar: '',
        user_name: '',
        phone: '',
        whatsapp: '',
        languages: '',
        email: '',
        address: {
            address: '',
            city: '',
            country: '',
            state: '',
            pin: '',
        },
        leadinquiry: '',
        generatedby: '',
        generatedatd: '',
        status: 'inactive',
        metadata: {
            avatar: '',
        }
    })
    const dispatch = useDispatch();
    const [messages, setMessages] = useState(bufferMessages)
    // const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
    const { currentUser, superauth, auth, currentGameContext } = useAuth()
    const token = superauth ? superauth?.api_token : auth?.api_token
    const location = useLocation()
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    const GameQuestions = useGameQuestions()
    const { chatUser } = usePageData()
    const [checkDelete, setCheckDelete] = useState(false);
    const [getName, setGetName] = useState();
    const [deleteData, setDeleteData] = useState();
    const [questionInfo, setQuestionInfo] = useState({});
    const [loaderBtn, setLoaderBtn] = useState(false);
    const [items, setItems] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [selectedQuestion, setSelectedQuestion] = useState({})
    const previewScrollToBottom = () => {
        const prev = document.getElementsByClassName("previewArea");
        if (prev) {
            map(prev, item => {
                if (item) {
                    item.scrollTop = item.scrollHeight;
                }
            })
        }
    }
    useEffect(() => {
        setItems(cloneDeep(GameQuestions));
        if (selectedQuestion && selectedQuestion.game_id) {
            const find_game_index = findIndex(GameQuestions, ["game_id", selectedQuestion.game_id]);
            if (find_game_index < 0) {
                setSelectedQuestion(GameQuestions?.at(-1))
                setTimeout(previewScrollToBottom, 1000)
            }
        } else {
            setSelectedQuestion(GameQuestions?.at(-1))
            setTimeout(previewScrollToBottom, 1000)
        }
    }, [GameQuestions])
    useEffect(() => {
        if (chatUser && chatUser !== '') {
            fetch(`${API_URL}/user/userById/${chatUser}`, {
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
            })
                .then((response) => response.json())
                .then((data) => { setFormData(data) })
                .catch((error) => console.error(error))

            fetch(`${API_URL}/lead_msg/msgById/${chatUser}`, {
                headers: {
                    'Content-Type': 'application/json',
                    token: token
                },
            })
                .then((response) => response.json())
                .then((data) => { bufferMessages = data?.data; setMessages(data?.data) })
                .catch((error) => console.error(error))


        }
    }, [chatUser])

    useEffect(() => {

        toggleChatUpdateFlat(!chatUpdateFlag)
    }, [])



    const getLeadUser = () => {
        fetch(`${API_URL}/Lead/leadById/${location?.state}`, {
            headers: {
                'Content-Type': 'application/json',
                token: token,
            },
        })
            .then((response) => response.json())
            .then((data) => setFormData(data?.data))
            .catch((error) => console.error(error))
    }

    const sendMessage = async () => {
        const newMessage = {
            // lead_id : location.state,
            reciever_id: formData?.user_id,
            sender_id: currentUser?.user_id.toString(),
            messagetype: 'out',
            text: message,
            time: ''
        }
        await axios.post(`/lead_msg/AddUpdateMsg`, newMessage)
        bufferMessages.push(newMessage)
        setMessages(bufferMessages)
        toggleChatUpdateFlat(!chatUpdateFlag)
        setMessage('')
        // setTimeout(() => {
        //   bufferMessages.push(messageFromClient)
        //   setMessages(() => bufferMessages)
        //   toggleChatUpdateFlat((flag) => !flag)
        // }, 1000)

        // there must be api call
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    const onDelete = (item) => {
        setCheckDelete(true);
        setGetName(getType(item?.type))
        setDeleteData(item?.id)
        setQuestionInfo(item?.question)
        // dispatch(deleteGameQuestion(item?.game_id))
        // dispatch(deleteGameQuestion(item?.id))
    }

    const onDuplicate = async (item) => {
        if (item) {
            const data = {
                org_id: item?.org_id,
                created_by: item?.created_by,
                type: item?.type,
                question: item?.question,
                answers: item?.answers,
                game_id: item?.game_id
            }
            const res = await dispatch(CreateGameQuestion(data))
            if (res) {
                openNotificationWithIcon('success')
            }
        }
    }

    const getType = (type) => {
        if (type === 'quiz') {
            return 'Quiz';
        } else if (type === 'true_or_false') {
            return 'True or False';
        } else if (type === 'type_answer') {
            return 'Type Answer';
        } else if (type === 'puzzle') {
            return 'Puzzle';
        } else if (type === 'poll') {
            return 'Poll';
        } else if (type === 'drop_pin') {
            return 'Drop Pin';
        } else if (type === 'word_cloud') {
            return 'Word Cloud';
        } else if (type === 'open_ended') {
            return 'Open Ended';
        } else if (type === 'brainstorm') {
            return 'Brainstorm';
        } else if (type === 'informational_page') {
            return 'Informational Page';
        } else if (type === 'flash_card') {
            return 'Flash-Card';
        } else {
            return '';
        }
    }

    const handleDeleteConfirmation = async () => {
        setLoaderBtn(true);
        await dispatch(deleteGameQuestion(deleteData));
        setLoaderBtn(false);
        setCheckDelete(false);
        setQuestionInfo({})
        // if (questionInfo?.imageUrl) {
        //     const storageRef = ref(storage, questionInfo?.imageUrl);
        //     deleteObject(storageRef).then(() => {
        //     }).catch((error) => {
        //         console.log('error', error);
        //     })
        // }
    }
    const handleDragEnd = async (result) => {
        if (!result.destination) return;
        const newItems = [...items];
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);
        setItems(newItems);
        const orderData = {
            new_order: newItems
        }
        await UpdateQuestionOrder(orderData)
        dispatch(getGameQuestion(currentGameId))
    };

    const getSelectedCard = (item, type) => {
        const cardTypes = {
            quiz: {
                id: 1,
                label: 'Quiz',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            true_or_false: {
                id: 2,
                label: 'True or false',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            type_answer: {
                id: 3,
                label: 'Type answer',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            puzzle: {
                id: 4,
                label: 'Puzzle',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            poll: {
                id: 1,
                label: 'Poll',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            drop_pin: {
                id: 2,
                label: 'Drop pin',
                icon: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            word_cloud: {
                id: 3,
                label: 'Word cloud',
                icon: <BiBorderAll />,
                starRate: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            open_ended: {
                id: 4,
                label: 'Open-ended',
                icon: <BiBorderAll />,
                starRate: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            brainstorm: {
                id: 5,
                label: 'Brainstorm',
                icon: <BiBorderAll />,
                starRate: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            informational_page: {
                id: 1,
                label: 'Informational Page',
                icon: <BiBorderAll />,
                starRate: <BiBorderAll />,
                isEditable: true,
                questionData: item
            },
            flash_card: {
                id: 1,
                label: 'Flash-Card',
                icon: <BiBorderAll />,
                starRate: <BiBorderAll />,
                isEditable: true,
                questionData: item
            }
        };

        return cardTypes[type] || '';
    };

    const handleCardSelect = (item) => {
        const type = get(item, 'type', '') ?? '';

        const Questions = get(item, 'question', '') ?? {};
        const time = get(Questions, 'time', '')
        const option = get(Questions, 'option', '')

        const selectedCard = getSelectedCard(item, type)
        dispatch(setActionTab({ selectedCard, handleCardSelect: null, showSection: true }))
        dispatch(setFeatures({ selectedTime: time, selectedOption: option }))
        setSelectedQuestion(item)
    }

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'The replica is created successfully.',
            // description:
            //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        });
    };

    return (
        <div className='layoutViewUpr bg-light'>
            {contextHolder}
            <Drawer
                title={`${title}`}
                placement={'right'}
                width={260}
                onClose={onClose}
                open={isOpen}
                className='layoutViewDrawr'
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', height: 100, width: '100%', padding: 8 }}>
                    {/* {map(GameQuestions, (item) => {
          const Questions = get(item, 'question', '');
          return ( */}
                    <>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div className='dndScroll'
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {items?.length > 0 && map(items, (item, index) => {
                                            const Questions = get(item, 'question', '') ?? {};
                                            const Answers = get(item, 'answers', '') ?? {};

                                            return (
                                                <Draggable
                                                    key={item?.id}
                                                    draggableId={item?.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div className='listLayoutCntnt' ref={provided.innerRef}  {...provided.draggableProps}  {...provided.dragHandleProps}>

                                                            <div className={selectedQuestion?.id === item?.id ? "listLayoutWrap-active" : "listLayoutWrap-inactive"} >

                                                                <div className='previewWidth'>
                                                                    <div className={selectedQuestion?.id === item?.id ? 'previewCardActive' : 'previewCard'}>
                                                                        <div className='previewIcon'>
                                                                            <i onClick={() => {
                                                                                onDuplicate(item)
                                                                            }} className="fa-solid fa-copy"></i>
                                                                            <i onClick={() => {
                                                                                onDelete(item)

                                                                            }} className="fa-solid fa-trash-can"></i>
                                                                        </div>
                                                                        <div className='previewLabel'>{getType(item?.type)}</div>
                                                                        <div className='previewUpr' onClick={() => {
                                                                            handleCardSelect(item);
                                                                        }}>
                                                                            <div className='previewList'>
                                                                                <p className='previewPara'>{get(Questions, 'title', '')}</p>
                                                                                {currentGameContext?.game_type === '1game' && <TimeDisplay item={item} /> }
                                                                                <div className='previewUpload'>  </div>
                                                                                <div className='imgPreview'>
                                                                                    {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={Questions?.imageUrl} height={32} alt="" />}
                                                                                </div>
                                                                                <div className='previewAns'>
                                                                                    {Answers?.options?.map((item, index) => {
                                                                                        return Answers?.answer && (Answers.answer.includes(item.toString()) || Answers.answer === item.toString().toLowerCase()) ? (
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><div key={index} className="dotVal" /></div>
                                                                                        ) : (
                                                                                            <div key={index} />
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}
                                                </Draggable>
                                            )
                                        }
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                </div>
            </Drawer>


            <div
                className="mobHide bg-light layoutView previewArea AddingScrollGPreview"
                // className="mobHide bg-light mobHideAddOns"
                style={{ width: open ? 260 : 0, height: '100vh', display: open === false || items.length > 0 ? 'block' : 'none' }}
            >
                <div onClick={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'grey', fontSize: 21, paddingTop: 15, paddingLeft: 15, cursor: 'pointer' }}>
                    <CloseCircleFilled style={{ marginRight: 10, fontWeight: '800' }} />

                    <span className='text-dark' style={{ fontSize: 18, fontWeight: '700' }}>Game Preview</span>

                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', height: 100, width: '100%', padding: 8 }}>
                    <>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div className='dndScroll'
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {items?.length > 0 && map(items, (item, index) => {
                                            const Questions = get(item, 'question', '') ?? {};
                                            const Answers = get(item, 'answers', '') ?? {};

                                            return (
                                                <Draggable
                                                    key={item?.id}
                                                    draggableId={item?.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div className='listLayoutCntnt' style={{marginBottom:5}} ref={provided.innerRef}  {...provided.draggableProps}  {...provided.dragHandleProps}>

                                                            <div className={selectedQuestion?.id === item?.id ? "listLayoutWrap-active" : "listLayoutWrap-inactive"} >

                                                                <div className='previewWidth'>
                                                                    <div className={selectedQuestion?.id === item?.id ? 'previewCardActive' : 'previewCard'}>
                                                                        <div className='previewIcon'>
                                                                            <i onClick={() => {
                                                                                onDuplicate(item)
                                                                            }} className="fa-solid fa-copy"></i>
                                                                            <i onClick={() => {
                                                                                onDelete(item)

                                                                            }} className="fa-solid fa-trash-can"></i>
                                                                        </div>
                                                                        <div className='previewLabel'>{getType(item?.type)}</div>
                                                                        <div className='previewUpr' onClick={() => {
                                                                            handleCardSelect(item);
                                                                        }}>
                                                                            <div className='previewList'>
                                                                                <p className='previewPara'>{get(Questions, 'title', '')}</p>
                                                                                {currentGameContext?.game_type === '1game' && <TimeDisplay item={item} />}
                                                                                <div className='previewUpload'>  </div>
                                                                                <div className='imgPreview'>
                                                                                    {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={Questions?.imageUrl} height={32} style={{width:'50%',objectFit:'contain'}} alt="" />}
                                                                                </div>
                                                                                <div className='previewAns'>
                                                                                    {Answers?.options?.map((item, index) => {
                                                                                        return Answers?.answer && (Answers.answer.includes(item.toString()) || Answers.answer === item.toString().toLowerCase()) ? (
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><div key={index} className="dotVal" /></div>
                                                                                        ) : (
                                                                                            <div key={index} />
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}
                                                </Draggable>
                                            )
                                        }
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                    {/* )
        })} */}
                </div>
            </div>
            <DeleteModal isOpens={checkDelete} isLoading={loaderBtn} isClose={setCheckDelete} title={`${getName} question`} message={'Are you sure you want to delete this question? This action can’t be undone.'} confirmDelete={() => {
                handleDeleteConfirmation();
            }} />
        </div>
    );
};
export default QuestionDrawer;
